<p-toast
    [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'flex-direction': 'column'}}">
</p-toast>
<p-toast position="bottom-center" key="c" (onClose)="onReject()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column" style="flex: 1">
            <div class="text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="grid p-fluid">
                <div class="col-6">
                    <button type="button" pButton (click)="onConfirm()" label="Yes" class="p-button-success"></button>
                </div>
                <div class="col-6">
                    <button type="button" pButton (click)="onReject()" label="No" class="p-button-secondary"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>

<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- ********************** Main Content ******************** -->

<div class="main-content d-flex flex-column">

    <!-- <app-dashboard-navbar></app-dashboard-navbar> -->
    <app-navbar-style-one></app-navbar-style-one>

    <!-- ********************** Breadcrumb Area  ******************** -->

    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <div class="add-listings-box">
        <div class="back_btn" style="padding: 0 15px;">
            <p-button type="button" routerLink="/custom-forms" icon="bx bx-arrow-back" label="" iconPos="left">
            </p-button>
        </div>
        <div class="top_section">
            <!-- <h3>Custom Forms List</h3> -->
            <!-- <div class="add-listings-btn"> -->
            <!-- [disabled]="!postMemberCategoriesForm.valid" -->
            <!-- <button type="submit" (click)="navigateToFormBuilder()"><i class="fa fa-plus mx-1"></i>Add</button> -->
            <div class="row m-0">
                <div class="col-md-6 col-12">
                    <label for="title">Form Title</label>
                    <input type="text" #form_name="ngModel" [(ngModel)]="formModel.form_name" class="form-control mb-2"
                        placeholder="Enter title here" required>
                    <div *ngIf="form_name.touched || errorMsg" class="alert alert-danger">
                        <div *ngIf="form_name.errors?.['required']">
                            Field is required.
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-12">
                    <label for="title">Award Category</label>
                    <select #award_category="ngModel" required class="form-select form-control mt-0 mb-2"
                        [(ngModel)]="formModel.awardCategory" aria-label="Default select example">
                        <option value="" disabled>Open this select menu</option>
                        <ng-container *ngFor="let item of awardCategory">
                            <option [value]="item.category">{{item.category}}</option>
                        </ng-container>
                    </select>
                    <div *ngIf="award_category.touched || errorMsg" class="alert alert-danger">
                        <div *ngIf="award_category.errors?.['required']">
                            Field is required.
                        </div>
                    </div>
                </div>
            </div>
            <!-- </div> -->
        </div>
    </div>
    <!-- <ng-container *ngIf="sectionsData?.length > 0"> -->
    <lib-form-builder [sections]="sections" (getFormData)="customFormData($event)">
    </lib-form-builder>
    <!-- </ng-container> -->
    <!-- {{sectionsData | json}} -->
    <!-- <lib-form-builder [buttonFields]="buttonFields" (formJson)="getJson($event)"></lib-form-builder> -->
    <!-- <lib-form-renderer *ngIf="jsonData" [jsonFormData]="jsonData"></lib-form-renderer> -->
    <!-- ********************** End Breadcrumb Area  ******************** -->

</div>