<div class="add-listings-box">

  <div class="wrapper green">
    <div class="header__wrapper">
      <div class="header">
        <div class="sign"><span></span></div>
      </div>
    </div>
    <!-- <h1>Yeah</h1> -->
    <div style="margin-top: 55px;">
      <p>Thank You For Registration!</p>
      <!-- <a routerLink="">Go to login page</a> -->
      <a (click)="onGotoLogin()">Go to login page</a>
    </div>
    <!-- <button routerLink="">Go to home page</button> -->
  </div>
</div>