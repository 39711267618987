<p-toast
    [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center'}}">
</p-toast>
<p-toast position="bottom-center" key="c" (onClose)="onReject()" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class="flex flex-column" style="flex: 1">
            <div class="text-center">
                <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                <h4>{{message.summary}}</h4>
                <p>{{message.detail}}</p>
            </div>
            <div class="grid p-fluid">
                <div class="col-6">
                    <button type="button" pButton (click)="onConfirm()" label="Yes" class="p-button-success"></button>
                </div>
                <div class="col-6">
                    <button type="button" pButton (click)="onReject()" label="No" class="p-button-secondary"></button>
                </div>
            </div>
        </div>
    </ng-template>
</p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- ********************** Main Content ******************** -->

<div class="main-content d-flex flex-column">

    <!-- <app-dashboard-navbar></app-dashboard-navbar> -->
    <app-navbar-style-one></app-navbar-style-one>

    <!-- ********************** Breadcrumb Area  ******************** -->

    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li>
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>

    <!-- ********************** End Breadcrumb Area  ******************** -->

    <!-- ********************** Add Master Form Area  ******************** -->
    <!-- <button type="button" pButton pRipple (click)="showError()" label="Error" class="p-button-danger mb-2"></button> -->
    <div class="add-listings-box">
        <!-- <div class="theader">
            <div class="flex">
                <span class="p-input-icon-left ml-auto mr-2">
                    <i class="pi pi-search"></i>
                    <input class="form-control" pInputText type="text"
                        (input)="dt2.filterGlobal($event.target.value, 'contains')"
                        placeholder="Search keyword" />
                </span>
                <div>
                    <p-button type="button" class="ml-2" (click)="navigateToFormBuilder()" icon="bx bx-plus" label="Add"
                    iconPos="right"></p-button>
                    <button type="submit" ><i class="fa fa-plus mx-1"></i>Add</button> -->
        <!-- </div>
            </div>
        </div> -->
        <!-- <div class="theader">
            <div class="d-flex search-bar">
                <span class="p-input-icon-left ml-auto mr-2">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" placeholder="Search keyword" [(ngModel)]="filterVal" />
                </span>
                <p-button type="button" class="ml-2" [(ngModel)]="filterVal" icon="bx
                bx-reset"></p-button>
            </div>
            <div class="add-listings-btn ml-2">
                <button type="button"> Send Email</button>
            </div>
        </div> -->
        <div class="theader">
            <div class="d-flex search-bar">
                <span class="p-input-icon-left ml-auto mr-2">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value, 'contains')"
                        placeholder="Search keyword" [(ngModel)]="filterVal" class="mr-2 input" />
                </span>
                <p-button type="button" class="ml-2" (click)="reset(dt2)" icon="bx bx-reset"></p-button>
                <!-- <p-button type="button" class="ml-2" (click)="reset(dt2)" icon="bx bx-reset"></p-button> -->
            </div>
            <div class="add">
                <p-button type="button" routerLink="/form-builder" class="add" icon="bx bx-plus" label="Add"
                    iconPos="right">
                </p-button>
            </div>
        </div>
        <!-- <div class="theader px-0">
            <div class="flex align-items-center">
                <span class="p-input-icon-left ml-auto d-flex align-items-center mr-2">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="dt2.filterGlobal($event.target.value, 'contains')"
                        placeholder="Search keyword" [(ngModel)]="filterVal" />
                    <p-button type="button" class="ml-2" (click)="reset(dt2)" icon="bx bx-reset"></p-button>
                </span>
                <div>
                    <p-button type="button" routerLink="/form-builder" icon="bx bx-plus" label="Add" iconPos="right">
                    </p-button>
                </div>
            </div>
        </div> -->
        <!-- <div class="top_section">
            <h3>Custom Forms List</h3>
            <div class="add-listings-btn">
               
            </div>
        </div> -->
        <p-table #dt2 [value]="forms" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
            styleClass="p-datatable-sm p-datatable-customers" [rowsPerPageOptions]="[10,25,50]"
            responsiveLayout="scroll" [loading]="loading" [paginator]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
            [globalFilterFields]="['form_title']">

            <ng-template pTemplate="header" style="min-height: 500px;">
                <tr>
                    <th>S. No.</th>
                    <th>Title</th>
                    <th>Award Category</th>
                    <th>Actions</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-forms let-i="rowIndex">
                <tr>
                    <td>
                        {{i+1}}
                    </td>
                    <td>
                        {{forms.form_title}}
                    </td>
                    <td>
                        {{forms.award_category}}
                    </td>
                    <td>
                        <button pButton pRipple icon="bx bx-pencil"
                            class="p-button-rounded p-button-text p-button-primary mr-2"
                            (click)="editFormDetail(forms)"></button>

                        <!-- <button pButton pRipple icon="bx bx-trash"
                            class="p-button-rounded p-button-text p-button-danger"
                            (click)="deleteFormDetail(forms.id)"></button> -->
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td colspan="5">No Data Found.</td>
                </tr>
            </ng-template>
        </p-table>
    </div>

    <!-- ********************** End Add Master Form Area  ******************** -->

    <!-- ********************** Master View Area  ******************** -->



    <!-- ********************** End Master View Area  ******************** -->

    <div class="flex-grow-1"></div>

    <!-- ********************** Copyrights Area  ******************** -->

    <!-- <app-copyrights></app-copyrights> -->

    <!-- ********************** End Copyrights Area  ******************** -->

</div>