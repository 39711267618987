<app-dashboard-sidemenu></app-dashboard-sidemenu>
<p-toast [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display:
  'flex', 'justify-content': 'center', 'align-items': 'center',
  'flex-direction': 'column'}}">
</p-toast>

<!-- ********************** Main Content ******************** -->

<div class="main-content d-flex flex-column">

    <!-- <app-dashboard-navbar></app-dashboard-navbar> -->
    <app-navbar-style-one></app-navbar-style-one>

    <!-- ********************** Breadcrumb Area  ******************** -->

    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <!-- <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li> -->
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>

    <div class="Master-view-box">
        <div class="theader">
            <!-- <ng-template pTemplate="caption"> -->
            <div class="d-flex search-bar">
                <span class="p-input-icon-left ml-auto mr-2">
                    <i class="pi pi-search"></i>
                    <input pInputText type="text" (input)="onSearch('dt1', $event.target.value)"
                        placeholder="Search keyword" [(ngModel)]="filterVal" />
                </span>
                <p-button type="button" class="ml-2" (click)="reset()" [(ngModel)]="filterVal" icon="bx
          bx-reset"></p-button>
            </div>
        </div>

        <div class="card">
            <p-tabView (onChange)="onTabChange($event)" styleClass="p-tabview-selected" id="teabview" (click)="tabClick($event)">
                
                <p-tabPanel header="CSR Award" [disabled]="isTabDisabled" id="csr">
                    <p-table #dt1 [value]="allControlsData" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
                        styleClass="p-datatable-sm
          p-datatable-customers" [rowsPerPageOptions]="[10,25,50]" editMode="row" responsiveLayout="scroll"
                        [paginator]="false" currentPageReportTemplate="Showing {first} to
          {last} of {totalRecords} entries" [globalFilterFields]="['name', 'status', 'company_name',
          'award.award_name', 'category']">
                        <ng-template pTemplate="header" style="min-height: 500px;">
                            <tr>
                                <th style="min-width: 100px; text-align: center; white-space:
                no-wrap;">S. No.</th>
                                <th>
                        
                                </th>
                                <th style="min-width: 150px; white-space: no-wrap;">Question</th>
                            </tr>
                        </ng-template>
                        <ng-container>
                            <ng-template pTemplate="body" let-item let-i="rowIndex">
                                <tr class="p-selectable-row">
                                    <td style="text-align: center;">{{i+1}}</td>
                                    <td>
                                     <input type="checkbox" (change)="handleCheckBoxClick($event, 'csr', item,i)" [(ngModel)]="toolListChecked[i]">
                                    </td>
                                    <td tooltipPosition="bottom" pTooltip="">
                                        {{item.label}}
                                    </td>
                                </tr>
                            </ng-template>
                        </ng-container>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="5">No data found.</td>
                            </tr>
                        </ng-template>

                    </p-table>
                    <div class="col-lg-2 col-md-12" style="width: 100%;">
                        <div style="display: flex; justify-content: flex-end;">
                            <button pButton (click)="saveData('csr')">Save</button>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Social Award" [disabled]="isTabDisabled">
                    <p-table #dt1 [value]="allControlsData" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
                        styleClass="p-datatable-sm
  p-datatable-customers" [rowsPerPageOptions]="[10,25,50]" editMode="row" responsiveLayout="scroll" [paginator]="false"
                        currentPageReportTemplate="Showing {first} to
  {last} of {totalRecords} entries" [globalFilterFields]="['name', 'status', 'company_name',
  'award.award_name', 'category']">
                        <ng-template pTemplate="header" style="min-height: 500px;">
                            <tr>
                                <th style="min-width: 100px; text-align: center; white-space:
        no-wrap;">S. No.</th>
                                <th>
                               
                                </th>
                                <th style="min-width: 150px; white-space: no-wrap;">Question</th>
                            </tr>
                        </ng-template>
                        <ng-container>
                            <ng-template pTemplate="body" let-item let-i="rowIndex">
                                <tr class="p-selectable-row">
                                    <td style="text-align: center;">{{i+1}}</td>
                                    <td>
                                        <input type="checkbox" (change)="handleCheckBoxClick($event, 'social', item, i)" [(ngModel)]="toolListChecked[i]">
                                    </td>
                                    <td tooltipPosition="bottom" pTooltip="">
                                        {{item.label}}
                                    </td>
                                </tr>
                            </ng-template>
                        </ng-container>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="5">No data found.</td>
                            </tr>
                        </ng-template>

                    </p-table>
                    <div class="col-lg-2 col-md-12" style="width: 100%;">
                        <div style="display: flex; justify-content: flex-end;">
                            <button pButton (click)="saveData('social')">Save</button>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Service Award" [disabled]="isTabDisabled" >
                    <p-table #dt1 [value]="allControlsData" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
                        styleClass="p-datatable-sm
  p-datatable-customers" [rowsPerPageOptions]="[10,25,50]" editMode="row" responsiveLayout="scroll" [paginator]="false"
                        currentPageReportTemplate="Showing {first} to
  {last} of {totalRecords} entries" [globalFilterFields]="['name', 'status', 'company_name',
  'award.award_name', 'category']">
                        <ng-template pTemplate="header" style="min-height: 500px;">
                            <tr>
                                <th style="min-width: 100px; text-align: center; white-space:
        no-wrap;">S. No.</th>
                                <th>
                                  
                                </th>
                                <th style="min-width: 150px; white-space: no-wrap;">Question</th>
                            </tr>
                        </ng-template>
                        <ng-container>
                            <ng-template pTemplate="body" let-item let-i="rowIndex">
                                <tr class="p-selectable-row">
                                    <td style="text-align: center;">{{i+1}}</td>
                                    <td>
                                        <input type="checkbox" (change)="handleCheckBoxClick($event, 'service', item,i)" [(ngModel)]="toolListChecked[i]">
                                    </td>
                                    <td tooltipPosition="bottom" pTooltip="">
                                        {{item.label}}
                                    </td>
                                </tr>
                            </ng-template>
                        </ng-container>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="5">No data found.</td>
                            </tr>
                        </ng-template>

                    </p-table>
                    <div class="col-lg-2 col-md-12" style="width: 100%;">
                        <div style="display: flex; justify-content: flex-end;">
                            <button pButton (click)="saveData('service')">Save</button>
                        </div>
                    </div>
                </p-tabPanel>
                <p-tabPanel header="Manufacturing Award" styleClass="p-tabview-selected" [disabled]="isTabDisabled">
                    <p-table #dt1 [value]="allControlsData" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
                        styleClass="p-datatable-sm
  p-datatable-customers" [rowsPerPageOptions]="[10,25,50]" editMode="row" responsiveLayout="scroll" [paginator]="false"
                        currentPageReportTemplate="Showing {first} to
  {last} of {totalRecords} entries" [globalFilterFields]="['name', 'status', 'company_name',
  'award.award_name', 'category']">
                        <ng-template pTemplate="header" style="min-height: 500px;">
                            <tr>
                                <th style="min-width: 100px; text-align: center; white-space:
        no-wrap;">S. No.</th>
                                <th>
                                
                                </th>
                                <th style="min-width: 150px; white-space: no-wrap;">Question</th>
                            </tr>
                        </ng-template>
                        <ng-container>
                            <ng-template pTemplate="body" let-item let-i="rowIndex">
                                <tr class="p-selectable-row">
                                    <td style="text-align: center;">{{i+1}}</td>
                                    <td>
                                        <input type="checkbox" (change)="handleCheckBoxClick($event, 'manufacturing', item,i)" [(ngModel)]="toolListChecked[i]">
                                    </td>
                                    <td tooltipPosition="bottom" pTooltip="">
                                        {{item.label}}
                                    </td>
                                </tr>
                            </ng-template>
                        </ng-container>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="5">No data found.</td>
                            </tr>
                        </ng-template>

                    </p-table>
                    <div class="col-lg-2 col-md-12" style="width: 100%;">
                        <div style="display: flex; justify-content: flex-end;">
                            <button pButton (click)="saveData('manufacturing')">Save</button>
                        </div>
                    </div>
                </p-tabPanel>
            </p-tabView>
        </div>

    </div>

    <app-copyrights></app-copyrights>
</div>