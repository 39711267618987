
<app-spinner></app-spinner>
<p-toast [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display:
    'flex', 'justify-content': 'center', 'align-items': 'center',
    'flex-direction': 'column'}}">
</p-toast>
<div class="main-content d-flex flex-column" *ngIf="signupState == false">
    <app-navbar-style-one></app-navbar-style-one>
    <div class="parallax">
        <div class="overlay">
            <div class="container custom-container">
                <div class="modal-content">
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                        <li class="nav-item">
                            <button class="nav-link {{classCheck ? 'show active' : ''}}" id="register-tab"
                                data-bs-toggle="tab" data-bs-target="#register" type="button" role="tab"
                                aria-controls="register" aria-selected="true" >Register</button>
                        </li>
                        <li class="nav-item">
                            <!-- {{classCheck ? 'show active' : ''}}" -->
                            <button  class="nav-link {{classCheck ? '' : 'show active'}}" id="login-tab"
                                data-bs-toggle="tab" data-bs-target="#login" type="button" role="tab"
                                aria-controls="login" aria-selected="true">Login</button>
                        </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                        
                        <div class="tab-pane fade {{classCheck ? 'show active' : ''}}" id="register" role="tabpanel">
                            <div class="vesax-register">
                                <!-- Register form -->
                                <form [formGroup]="registerForm" (ngSubmit)="signUp(signupForm)" #signupForm="ngForm"  style="width: 100%;">
                                    <!-- pop ups -->
                                    <div styleClass="custombar2">
                                        <div class="row padding">
                                            <div class="col-lg-3 col-md-12">
                                                <div class="form-group"><label>Contact
                                                        Person Name<span class="requierdSign">*</span>:</label>
                                                    <input type="text" formControlName="name"
                                                        style="text-transform: capitalize;"
                                                        placeholder="Contact Person name" class="form-control"
                                                        maxlength="70" pattern="[a-zA-Z0-9]|[./!@#$%^&*()-_,?]"
                                                        [class.is-invalid]="(signupForm.submitted
                                                        &&
                                                        registerForm.get('name')?.invalid)
                                                        ||
                                                        registerForm.get('name')?.invalid
                                                        &&
                                                        registerForm.get('name').touched">
                                                    <!-- <div class="invalid-feedback">User Name is Required</div> -->
                                                    <div *ngIf="signupForm.submitted
                                                        &&
                                                        f.name.errors?.required
                                                        ||
                                                        f.name.touched" class="alert
                                                        alert-danger">
                                                        <div *ngIf="f.name.errors?.required">
                                                            Contact Person Name
                                                            is Required
                                                        </div>
                                                    </div>
                                                    <div *ngIf="f.name.errors?.pattern" class="alert
                                                        alert-danger">
                                                        Contact Person Name
                                                        is
                                                        Invalid
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-12">
                                                <div class="form-group"><label>
                                                        Mobile
                                                        Number<span class="requierdSign">*</span>:
                                                    </label>
                                                    <input type="text" formControlName="mobile_no"
                                                        placeholder="Mobile Number" class="form-control" maxlength="10" minlength="10"
                                                        [class.is-invalid]="(signupForm.submitted
                                                        &&
                                                        registerForm.get('mobile_no')?.invalid)
                                                        ||
                                                        registerForm.get('mobile_no')?.invalid
                                                        &&
                                                        registerForm.get('mobile_no').touched" >
                                                    <!-- <div class="invalid-feedback">Mobile number is Required</div> -->
                                                    <div *ngIf="signupForm.submitted
                                                        &&
                                                        f.mobile_no.errors?.required
                                                        ||
                                                        f.mobile_no.touched" class="alert
                                                        alert-danger">
                                                        <div *ngIf="f.mobile_no.errors?.required">
                                                            Mobile Number is
                                                            Required
                                                        </div>
                                                    </div>
                                                    <div *ngIf="f.mobile_no.errors?.minlength
                                                        ||
                                                        f.mobile_no.errors?.maxlength
                                                        ||
                                                        f.mobile_no.errors?.pattern" class="alert
                                                        alert-danger">
                                                        Mobile Number
                                                        is
                                                        Invalid
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-12">
                                                <div class="form-group"><label>Email<span
                                                            class="requierdSign">*</span>:</label>
                                                    <input type="email" formControlName="email" placeholder="Email"
                                                        class="form-control" maxlength="50"
                                                        pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
                                                        [class.is-invalid]="(signupForm.submitted
                                                        &&
                                                        registerForm.get('email')?.invalid)
                                                        ||
                                                        registerForm.get('email')?.invalid
                                                        &&
                                                        registerForm.get('email').touched">
                                                    <div *ngIf="signupForm.submitted
                                                        &&
                                                        f.email.errors?.required
                                                        ||
                                                        f.email.touched" class="alert
                                                        alert-danger">
                                                        <div *ngIf="f.email.errors?.required
                                                            ||
                                                            f.email.errors?.submit">
                                                            Email is Required
                                                        </div>
                                                    </div>
                                                    <div *ngIf="f.email.errors?.pattern" class="alert
                                                        alert-danger">
                                                        Invalid Email
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-12">
                                                <div class="form-group"><label>Organization Name (Legal
                                                        Name)<span class="requierdSign">*</span>:</label>
                                                    <input type="text" maxlength="70"
                                                        pattern="[a-zA-Z0-9]|[./!@#$%^&*()-_,?]"
                                                        placeholder="Organization Name"
                                                        style="text-transform: capitalize;"
                                                        formControlName="company_name" [class.is-invalid]="(signupForm.submitted
                                                        &&
                                                        registerForm.get('company_name')?.invalid)
                                                        ||
                                                        registerForm.get('company_name')?.invalid
                                                        &&
                                                        registerForm.get('company_name').touched" class="form-control">
                                                    <!-- <div class="invalid-feedback">Company name is Required</div> -->
                                                    <div *ngIf="signupForm.submitted
                                                        &&
                                                        f.company_name.errors?.required
                                                        ||
                                                        f.company_name.touched" class="alert
                                                        alert-danger">
                                                        <div *ngIf="f.company_name.errors?.required">
                                                            Organization Name (Legal
                                                            Name) is
                                                            Required
                                                        </div>

                                                    </div>
                                                    <div *ngIf="f.company_name.errors?.pattern" class="alert
                                                        alert-danger">
                                                        Organization Name (Legal
                                                        Name)
                                                        is
                                                        Invalid
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-12">
                                                <div class="form-group"><label>Award
                                                        Type<span class="requierdSign">*</span>:
                                                    </label> <select formControlName="award_type" [class]="(signupForm.submitted
                                                        &&
                                                        registerForm.get('award_type')?.invalid)
                                                        ||
                                                        registerForm.get('award_type')?.invalid
                                                        &&
                                                        registerForm.get('award_type').touched
                                                        ? 'form-control' : ''" (change)="onAwardTypeSelection($event)"
                                                        [class.is-invalid]="(signupForm.submitted
                                                        &&
                                                        registerForm.get('award_type')?.invalid)
                                                        ||
                                                        registerForm.get('award_type')?.invalid
                                                        &&
                                                        registerForm.get('award_type').touched" #value>
                                                        <option [value]="null" disabled>Select
                                                            Award Type
                                                        </option>
                                                        <option value="excellence">Excellence
                                                            Award</option>
                                                        <option value="csr">CSR
                                                            Award</option>
                                                        <option value="se">Social
                                                            Enterprises Award</option>
                                                    </select>
                                                    <div class="invalid-feedback">Award
                                                        Type is Required</div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-12">
                                                <div class="form-group"><label>
                                                        PAN<span class="requierdSign">*</span>:
                                                    </label>
                                                    <input type="text" formControlName="company_PAN" oninput="this.value=
                                                        this.value.toUpperCase()" placeholder="PAN"
                                                        class="form-control" pattern="[A-Z]{5}[0-9]{4}[A-Z]{1}"
                                                        maxlength="10" [class.is-invalid]="(signupForm.submitted
                                                        &&
                                                        registerForm.get('company_PAN')?.invalid)
                                                        ||
                                                        registerForm.get('company_PAN')?.invalid
                                                        &&
                                                        registerForm.get('company_PAN').touched">
                                                    <!-- <div class="invalid-feedback">PAN number is Required</div> -->
                                                    <div *ngIf="signupForm.submitted
                                                        &&
                                                        f.company_PAN.errors?.required
                                                        ||
                                                        f.company_PAN.touched" class="alert
                                                        alert-danger">
                                                        <div *ngIf="f.company_PAN.errors?.required">
                                                            PAN is Required
                                                        </div>
                                                    </div>

                                                    <div *ngIf="f.company_PAN.errors?.pattern" class="alert
                                                        alert-danger">
                                                        PAN
                                                        is
                                                        Invalid
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-12">
                                                <div class="form-group"><label>
                                                        GST
                                                        Registration Type<span class="requierdSign">*</span>:
                                                    </label>
                                                    <select formControlName="gst_type" [class]="(signupForm.submitted
                                                        &&
                                                        registerForm.get('gst_type')?.invalid)
                                                        ||
                                                        registerForm.get('gst_type')?.invalid
                                                        &&
                                                        registerForm.get('gst_type').touched
                                                        ? 'form-control' : ''"
                                                        (change)="onGstTypeSelection($event.target.value)"
                                                        [class.is-invalid]="(signupForm.submitted
                                                        &&
                                                        registerForm.get('gst_type')?.invalid)
                                                        ||
                                                        registerForm.get('gst_type')?.invalid
                                                        &&
                                                        registerForm.get('gst_type').touched" #value>
                                                        <option [value]="null" disabled>Select GST
                                                            Type
                                                        </option>
                                                        <option value="registered">Registered</option>
                                                        <option value="nonRegistered">Non
                                                            Registered</option>
                                                    </select>
                                                    <div class="invalid-feedback">GST
                                                        Registration Type is
                                                        Required
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-12">
                                                <div class="form-group" *ngIf="gstType ===
                                                    'registered'"><label>
                                                        GST Number<span class="requierdSign">*</span>:
                                                    </label>
                                                    <input type="text" formControlName="company_GST"
                                                        placeholder="GST Number" class="form-control" maxlength="15"
                                                        oninput="this.value=
                                                        this.value.toUpperCase()"
                                                        pattern="^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
                                                        [class.is-invalid]="(signupForm.submitted
                                                        &&
                                                        registerForm.get('company_GST')?.invalid)
                                                        ||
                                                        registerForm.get('company_GST')?.invalid
                                                        &&
                                                        registerForm.get('company_GST').touched">
                                                    <div *ngIf="signupForm.submitted
                                                        &&
                                                        f.company_GST.errors?.required
                                                        ||
                                                        f.company_GST.touched" class="alert
                                                        alert-danger">
                                                        <div *ngIf="f.company_GST.errors?.required">
                                                            GST Number is
                                                            Required
                                                        </div>
                                                    </div>
                                                    <div *ngIf="f.company_GST.errors?.pattern" class="alert
                                                        alert-danger">
                                                        GST Number
                                                        is
                                                        Invalid
                                                    </div>
                                                </div>
                                                <div class="form-group" *ngIf="gstType ===
                                                    'nonRegistered' ||
                                                    this.registerForm.value.gst_type
                                                    === null">
                                                    <label style="color: grey;">GST
                                                        Number:</label>
                                                    <span class="form-control" style="color: #999999;
                                                        display: flex;
                                                        align-items: center;">GST
                                                        Number</span>
                                                </div>
                                            </div>


                                            <div class="col-lg-3 col-md-12">
                                                <div class="form-group"><label>
                                                        Industry
                                                        Classification<span class="requierdSign">*</span>:
                                                    </label>
                                                    <!-- {{classification | groupByPipe : 'category' | json}} -->
                                                    <p-dropdown [options]="classification
                                                        | groupByPipe :
                                                        'category'" formControlName="classification_id" placeholder="Select
                                                        Industry Classification" [group]="true" [class.is-invalid]="(signupForm.submitted
                                                        &&
                                                        registerForm.get('classification_id')?.invalid)
                                                        ||
                                                        registerForm.get('classification_id')?.invalid
                                                        &&
                                                        registerForm.get('classification_id').touched">
                                                        <!-- <p-scrollPanel [style]="{width: '10px'}"> -->
                                                        <ng-template let-item pTemplate="selectedItem">
                                                            {{item.name}}
                                                        </ng-template>
                                                        <ng-template let-group pTemplate="group">
                                                            <div class="p-d-flex
                                                                p-ai-center">
                                                                <span>{{group.name}}</span>
                                                            </div>
                                                        </ng-template>
                                                        <ng-template let-item pTemplate="item">
                                                            {{item.name}}
                                                        </ng-template>
                                                        <!-- </p-scrollPanel> -->
                                                    </p-dropdown>
                                                    <div class="invalid-feedback">
                                                        Industry
                                                        Classification is
                                                        Required
                                                    </div>
                                                    <!-- <div class="invalid-feedback">Industry classification is Required
                                                    </div> -->
                                                    <!-- <select formControlName="classification_id"
                                                        [class]="(signupForm.submitted && registerForm.get('classification_id')?.invalid) || registerForm.get('classification_id')?.invalid && registerForm.get('classification_id').touched ? 'form-control' : ''"
                                                        (change)="onDropdownSelection($event, 'classification')"
                                                        [class.is-invalid]="(signupForm.submitted && registerForm.get('classification_id')?.invalid) || registerForm.get('classification_id')?.invalid && registerForm.get('classification_id').touched">
                                                        <option [value]="null" disabled>Please select classification
                                                        </option>
                                                        <option [ngValue]="item" *ngFor="let item of classification"
                                                            style="white-space: nowrap;">{{item.name}}
                                                        </option>
                                                    </select> -->
                                                </div>
                                            </div>
                                            <!-- <div class="col-lg-6 col-md-12">
                                            <div class="form-group">Service <span class="requierdSign">*</span>:
                                                <select formControlName="service_id"
                                                    (change)="onDropdownSelection($event, 'service')"
                                                    [class.is-invalid]="serviceState == true">
                                                    <option [value]="null" disabled>Please select service</option>
                                                    <option [ngValue]="item" *ngFor="let item of service">{{item.name}}
                                                    </option>
                                                </select>
                                                <div class="invalid-feedback">Service is Required</div>
                                            </div>
                                        </div> -->
                                            <div class="col-lg-3 col-md-12">
                                                <div class="form-group">
                                                    <!-- <label>
                                                        Turnover in INR
                                                        ({{preYear}}-{{date | date:
                                                        'yy'}})

                                                        <span class="requierdSign">*</span>:
                                                    </label> -->
                                                    <label>
                                                        Turnover in INR
                                                        (FY 2023-24)

                                                        <span class="requierdSign">*</span>:
                                                    </label>
                                                    <!-- <select formControlName="turnover"
                                                    [class]="(signupForm.submitted && registerForm.get('turnover')?.invalid) || registerForm.get('turnover')?.invalid && registerForm.get('turnover').touched ? 'form-control' : ''"
                                                    (change)="onDropdownSelection($event, 'turnover')"
                                                    [class.is-invalid]="(signupForm.submitted && registerForm.get('turnover')?.invalid) || registerForm.get('turnover')?.invalid && registerForm.get('turnover').touched">
                                                    <option [value]="null" disabled>Please select turnover</option>
                                                    <option [ngValue]="item" *ngFor="let item of turnover">
                                                        {{item.component}}
                                                    </option>
                                                </select> -->
                                                    <p-inputNumber pattern="[a-zA-Z0-9]|[./!@#$%^&*()-_,?]"
                                                        inputId="locale-indian" mode="decimal" locale="en-IN" prefix="₹"
                                                        placeholder="Turnover" formControlName="turnover" maxlength="22"
                                                        required [class.is-invalid]="(signupForm.submitted
                                                        &&
                                                        registerForm.get('turnover')?.invalid)
                                                        ||
                                                        registerForm.get('turnover')?.invalid
                                                        &&
                                                        registerForm.get('turnover').touched">
                                                    </p-inputNumber>
                                                    <!-- <div class="invalid-feedback">Turnover is Required</div> -->
                                                    <div *ngIf="signupForm.submitted
                                                        &&
                                                        f.turnover.errors?.required
                                                        ||
                                                        f.turnover.touched" class="alert
                                                        alert-danger">
                                                        <div *ngIf="f.turnover.errors?.required">
                                                            Turnover is Required
                                                        </div>
                                                        <div *ngIf="f.turnover.errors?.min" class="alert
                                                            alert-danger">
                                                            Minimum Turnover
                                                            Amount Must be 1
                                                            Lakh
                                                        </div>
                                                    </div>
                                                    <!-- <div *ngIf="f.turnover.errors?.min" class="alert
                                                alert-danger">
                                                    GST Number
                                                    is
                                                    invalid
                                                </div> -->
                                                    <!-- <select formControlName="turnover">
                                                    <option [value]="null" disabled>Please select turnover</option>
                                                    <option *ngFor="let item of turnover">{{item.component}}
                                                    </option>
                                                </select> -->
                                                </div>
                                            </div>

                                            <div class="col-lg-3 col-md-12">
                                                <div class="form-group"><label>
                                                        Address
                                                        Line 1<span class="requierdSign">*</span>:
                                                    </label>
                                                    <textarea type="text" formControlName="company_address"
                                                        placeholder="Enter Address Line 1" class="form-control"
                                                        pattern="[a-zA-Z0-9]|[./!@#$%^&*()-_,?]" [class.is-invalid]="(signupForm.submitted
                                                        &&
                                                        registerForm.get('company_address')?.invalid)
                                                        ||
                                                        registerForm.get('company_address')?.invalid
                                                        &&
                                                        registerForm.get('company_address').touched"></textarea>
                                                    <!-- <div class="invalid-feedback">Address
                                                        Line 1 is Required</div> -->
                                                    <div *ngIf="signupForm.submitted
                                                        &&
                                                        f.company_address.errors?.required
                                                        ||
                                                        f.company_address.touched" class="alert
                                                        alert-danger">
                                                        <div *ngIf="f.company_address.errors?.required">
                                                            Address
                                                            Line 1 is Required
                                                        </div>
                                                        <div *ngIf="f.company_address.errors?.pattern" class="alert
                                                            alert-danger">
                                                            Address
                                                            Line 1 is Invalid
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-12">
                                                <div class="form-group"><label>Address
                                                        Line 2:</label>
                                                    <textarea pattern="[a-zA-Z0-9]|[./!@#$%^&*()-_,?]" type="text"
                                                        formControlName="address_line2" [class.is-invalid]="(signupForm.submitted
                                                    &&
                                                    registerForm.get('address_line2')?.invalid)
                                                    ||
                                                    registerForm.get('address_line2')?.invalid
                                                    &&
                                                    registerForm.get('address_line2').touched"
                                                        placeholder="Enter Address Line 2"
                                                        class="form-control"></textarea>
                                                    <!-- <div class="invalid-feedback">Address
                                                        Line 2 is Required</div> -->
                                                    <div *ngIf="signupForm.submitted
                                                        &&
                                                        f.address_line2.errors?.required
                                                        ||
                                                        f.address_line2.touched" class="alert
                                                        alert-danger">
                                                        <div *ngIf="f.address_line2.errors?.required">
                                                            Address
                                                            Line 2 is Required
                                                        </div>
                                                        <div *ngIf="f.address_line2.errors?.pattern" class="alert
                                                            alert-danger">
                                                            Address
                                                            Line 2 is Invalid
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-12">
                                                <div class="form-group">
                                                    <label style="margin-top:
                                                        5px;">State<span class="requierdSign">*</span>:</label>
                                                    <p-dropdown [options]="state" formControlName="company_state"
                                                        optionLabel="name" [filter]="true" filterBy="name"
                                                        [showClear]="true" placeholder="Select
                                                        State" [(ngModel)]="selectedState" [class.is-invalid]="(signupForm.submitted
                                                        &&
                                                        registerForm.get('company_state')?.invalid)
                                                        ||
                                                        registerForm.get('company_state')?.invalid
                                                        &&
                                                        registerForm.get('company_state').touched">
                                                        <ng-template pTemplate="selectedItem">
                                                            <div class="state-item
                                                                state-item-value"
                                                                *ngIf="registerForm.value.company_state.name">
                                                                <div>{{registerForm.value.company_state.name}}</div>
                                                            </div>
                                                        </ng-template>
                                                        <ng-template let-item pTemplate="item">
                                                            <div class="state-item">
                                                                <div>{{item.name}}</div>
                                                            </div>
                                                        </ng-template>
                                                    </p-dropdown>
                                                    <div class="invalid-feedback">State is Required</div>
                                                    <!-- <div class="invalid-feedback">State is Required</div> -->
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-12">
                                                <div class="form-group"><label>
                                                        City<span class="requierdSign">*</span>:
                                                    </label>
                                                    <input type="text" formControlName="company_city" placeholder="City"
                                                        pattern="[a-zA-Z0-9]|[./!@#$%^&*()-_,?]" class="form-control"
                                                        [class.is-invalid]="(signupForm.submitted
                                                        &&
                                                        registerForm.get('company_city')?.invalid)
                                                        ||
                                                        registerForm.get('company_city')?.invalid
                                                        &&
                                                        registerForm.get('company_city').touched">
                                                    <!-- <div class="invalid-feedback">City
                                                        is Required</div> -->
                                                    <div *ngIf="signupForm.submitted
                                                        &&
                                                        f.company_city.errors?.required
                                                        ||
                                                        f.company_city.touched" class="alert
                                                        alert-danger">
                                                        <div *ngIf="f.company_city.errors?.required">
                                                            City is Required
                                                        </div>
                                                    </div>
                                                    <div *ngIf="f.company_city.errors?.pattern" class="alert
                                                        alert-danger">
                                                        City is Invalid
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-3 col-md-12">
                                                <div class="form-group"><label>Pincode<span
                                                            class="requierdSign">*</span>:</label>
                                                    <input type="text" formControlName="company_pincode"
                                                        pattern="[a-zA-Z0-9]|[./!@#$%^&*()-_,?]" placeholder="Pincode"
                                                        class="form-control" maxlength="6" minlength="6"
                                                        [class.is-invalid]="(signupForm.submitted
                                                        &&
                                                        registerForm.get('company_pincode')?.invalid)
                                                        ||
                                                        registerForm.get('company_pincode')?.invalid
                                                        &&
                                                        registerForm.get('company_pincode').touched">
                                                    <!-- <div class="invalid-feedback">Pincode is Required</div> -->
                                                    <div *ngIf="signupForm.submitted
                                                        &&
                                                        f.company_pincode.errors?.required
                                                        ||
                                                        f.company_pincode.touched" class="alert
                                                        alert-danger">
                                                        <div *ngIf="f.company_pincode.errors?.required">
                                                            Pincode is Required
                                                        </div>
                                                    </div>
                                                    <div *ngIf="f.company_pincode.errors?.minlength
                                                        ||
                                                        f.company_pincode.errors?.maxlength
                                                        ||
                                                        f.company_pincode.errors?.pattern" class="alert
                                                        alert-danger">
                                                        Pincode
                                                        is
                                                        Invalid
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group"><label>
                                                        Mention
                                                        2-3 Key Achievements In
                                                        Terms of
                                                        Business Operations /
                                                        Innovation Etc Which
                                                        Emphasize Why Your
                                                        Organization Qualifies For A
                                                        Consideration For Award(s).<span class="requierdSign">*</span>:
                                                    </label>
                                                    <textarea type="text" formControlName="achievements" maxlength="400"
                                                        placeholder="Enter Achievement" class="form-control"
                                                        pattern="[a-zA-Z0-9]|[./!@#$%^&*()-_,?]" [class.is-invalid]="(signupForm.submitted
                                                        &&
                                                        registerForm.get('achievements')?.invalid)
                                                        ||
                                                        registerForm.get('achievements')?.invalid
                                                        &&
                                                        registerForm.get('achievements').touched"></textarea>
                                                    <!-- <div class="invalid-feedback">Key
                                                        Achievement is Required</div> -->
                                                    <div *ngIf="signupForm.submitted
                                                        &&
                                                        f.achievements.errors?.required
                                                        ||
                                                        f.achievements.touched" class="alert
                                                        alert-danger">
                                                        <div *ngIf="f.achievements.errors?.required">
                                                            Key
                                                            Achievement is Required
                                                        </div>
                                                    </div>
                                                    <div *ngIf="f.achievements.errors?.pattern" class="alert
                                                        alert-danger">
                                                        Key
                                                        Achievement is Invalid
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12 col-md-12">
                                                <div class="form-group"><label>
                                                        Mention
                                                        upto 3 Key Strengths / USPs
                                                        ( Unique
                                                        Features) of Your Enterprise<span class="requierdSign">*</span>:
                                                    </label>
                                                    <textarea type="text" formControlName="reason"
                                                        placeholder="Enter Strength" class="form-control"
                                                        pattern="[a-zA-Z0-9]|[./!@#$%^&*()-_,?]" maxlength="400"
                                                        [class.is-invalid]="(signupForm.submitted
                                                        &&
                                                        registerForm.get('reason')?.invalid)
                                                        ||
                                                        registerForm.get('reason')?.invalid
                                                        &&
                                                        registerForm.get('reason').touched"></textarea>
                                                    <!-- <div class="invalid-feedback">Key
                                                        Strength is Required</div> -->
                                                    <div *ngIf="signupForm.submitted
                                                        &&
                                                        f.reason.errors?.required
                                                        ||
                                                        f.reason.touched" class="alert
                                                        alert-danger">
                                                        <div *ngIf="f.reason.errors?.required">
                                                            Key
                                                            Strength is Required
                                                        </div>
                                                    </div>
                                                    <div *ngIf="f.reason.errors?.pattern" class="alert
                                                        alert-danger">
                                                        Key
                                                        Strength is Invalid
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="col-lg-2 col-md-12">
                                        <div style="padding-top: 10px; padding-inline: 10px;">
                                            <button type="submit">Register Now</button>
                                        </div>
                                    </div>
                                    </div>

                                </form>
                                <!-- <span class="already-account">Already have an account?
                                <a href="#">Login Now</a></span> -->
                            </div>
                        </div>
                        <div class="tab-pane fade {{classCheck ? '' : 'show active'}} " id="login" role="tabpanel">
                            <div class="vesax-login">
                                <!-- Login form -->
                                <form [formGroup]="loginForm" (ngSubmit)="otpGenerate()" #login="ngForm" style="width: 100%; margin-right: 15vw;">
                                    <div class="form-group"><label>
                                            Email<span class="requierdSign">*</span>:
                                        </label>
                                        <input type="email" placeholder="Email" formControlName="loginemail"
                                            [class.is-invalid]="(login.submitted
                                            &&
                                            loginForm.get('loginemail')?.invalid)
                                            ||
                                            loginForm.get('loginemail')?.invalid
                                            &&
                                            loginForm.get('loginemail')?.touched" oninput="this.value=
                                            this.value.toLowerCase()" class="form-control"
                                            pattern="^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$">
                                        <div *ngIf="login.submitted &&
                                            g.loginemail.errors?.required ||
                                            g.loginemail.touched" class="alert
                                            alert-danger">
                                            <div *ngIf="g.loginemail.errors?.required">Email
                                                is Required
                                            </div>
                                        </div>
                                        <div *ngIf="g.loginemail.errors?.pattern" class="alert alert-danger">
                                            Invalid Email Address
                                        </div>
                                    </div>
                                    <!-- <div class="form-group" style="position: relative;">Password<span
                                            class="requierdSign">*</span>:
                                        <input [type]="passwordState == true ? 'text' : 'password'"
                                            formControlName="loginpassword" placeholder="Password" class="form-control"> -->
                                    <!-- <div (click)="onShowHidePasswordClick()"
                                            [pTooltip]="passwordState == true ? 'Hide' : 'Show'"
                                            tooltipPosition="bottom"
                                            style="position: absolute; right: 10px; top: 34px; cursor: pointer;">
                                            <div *ngIf="passwordState == true ; else elseBlock">
                                                <span class="bx bx-hide" style="font-size: 1.2rem"></span>
                                            </div>
                                            <ng-template #elseBlock>
                                                <span class="bx bx-show" style="font-size: 1.2rem"></span>
                                            </ng-template>
                                        </div> -->
                                    <div class="form-group" *ngIf="otpState ===
                                        true"><label>
                                            OTP<span class="requierdSign">*</span>:
                                        </label>
                                        <input type="text" placeholder="Enter OTP" formControlName="otp"
                                            class="form-control" pattern="^[0-9]{6}" maxlength="6">
                                        <div *ngIf="login.submitted &&
                                            g.otp.errors?.required ||
                                            g.otp.touched" class="alert
                                            alert-danger">
                                            <div *ngIf="g.otp.errors?.required">OTP
                                                is Required
                                            </div>
                                        </div>
                                        <div *ngIf="g.otp.errors?.pattern" class="alert alert-danger">
                                            Invalid OTP
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12" style="text-align: right;">
                                                <span class="otpBtn" (click)="onResendOtp()">Resend
                                                    OTP</span>
                                            </div>
                                        </div>

                                    </div>
                                    <!-- </div> -->
                                    <button type="submit">{{otpState === false ?
                                        'Send OTP' : 'Login Now'}}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <app-copyrights></app-copyrights>
</div>
<!-- </div>
</div> -->

<div *ngIf="signupState == true">
    <app-success-page (clickState)="onLoginClick($event)"></app-success-page>
</div>