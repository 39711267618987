import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { Table } from 'primeng/table';
import { CommonClass } from 'src/app/common';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilderService } from 'src/app/services/form-builder.service';

@Component({
  selector: 'app-stage-two-list',
  templateUrl: './stage-two-list.component.html',
  styleUrls: ['./stage-two-list.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class StageTwoListComponent implements OnInit {
  @ViewChild('dt1') dt1: Table;
  @ViewChild('dt2') dt2: Table;
  breadcrumb: any[];

  filterVal: string;

  loading: boolean = false;

  selectedProducts : any = []

  users: any = [];

  displayMaximizable: boolean = false;

  details: any;

  statusModal: boolean = false;

  id: number;

  preYear: any;

  currentYear: any;

  date: Date = new Date();
  currncyLength = 0;
  multiSelectFilterVal: any[];
  // <<<<<<< HEAD
  //   currency;
  // =======
  //   currency
  // >>>>>>> 79b965bb3f33b39ee216fc353fe076e480be689d

  statusForm = this.fb.group({
    status: ['', Validators.required],
    remark: ['', Validators.required],
  });
  status: string;
  localStorage: any;
  selectedUser: any[] = [];
  turnoverAmount: string;
  checkAll: boolean = false;
  mailArray: any = [];
  uploadData : any = [{results_ids : '', result_pdf : ''}]
  statusList:any = [{
    label: 'Completed',
    value:'Completed',
  },{
    label: 'In Progress',
    value: 'In Progress',
  },{
    label: 'Pending',
    value: '',
  }
  ]
  // emailBoolean: boolean = false;

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private commonFunction: CommonClass,
    private confirmationService: ConfirmationService,
    private formService: FormBuilderService,
    private route: Router,
    private _formBuilder : FormBuilderService
  ) {}

  async ngOnInit() {
    this.spinner.show();
    this.localStorage = this.commonFunction.getLocalStorage();
    this.getUsers();
    this.breadcrumb = [
      {
        title: 'Stage Two',
        subTitle: 'Home',
      },
    ];
    if(this.localStorage.role == 'Admin') {
      await this._formBuilder.getAPI('response/allData').then((res:any)=> {
        this.spinner.hide();
        console.log(res);
        this.users = res?.data;
      })
    }
    this.currentYear = this.date.getFullYear();
    this.preYear = this.currentYear - 1;
  }

  // get All registered users

  getUsers() {
    // this.apiService.getRegisteredUsers().subscribe((res: any) => {
    //   console.log(res);
    //   let users: any = res.data;
    //   this.users = users.reverse();
    // });
      this.formService.getAPI('formData').then((res: any) => {
        this.users = res?.data;
        console.log(this.users);
        this.spinner.hide();
      });
  }

  // On edit form Press
  editForm(slug, status,company_name, res_id) {
    // console.log(i);
    if (status != 'completed') {
      // let array = this.users.filter(x => x.award_category == category && x.status == "In Progress");
      // let routeIndex:any = 0;
      // array.forEach((form:any, index:any) => {
      //   if(form.company_name == company_name && slug == form.slug) {
      //     routeIndex = index;
      //     console.log("routeIndex",routeIndex)
      //   }
      // });
      // if(status == "") {
      //   routeIndex = 0;
      // }
      // else {

      // }
      // console.log(array);
      localStorage.setItem('company',company_name);
      localStorage.setItem('res_id',res_id);
      this.route.navigateByUrl(`/form-lib/${slug}`);
    }
    // localStorage.setItem('companyName', name);
  }

  // search function
  onMultiSelect(type: string, e: any) {
    if (type === "dt2") {
      this.dt2.filterGlobal(e, 'contains');
    }
  }
  
  onSearch(type: string, e: any) {
    if (type === "dt1") {
      this.dt1.filterGlobal(e, 'contains');
    } else if (type === "dt2") {
      this.dt2.filterGlobal(e, 'contains');
    }
  }

  // Clear search filter
  reset() {
    this.dt1.reset();
    this.filterVal = '';
  }

  // siteVisit() {
  //   this.route.navigateByUrl('site-visit');
  // }
  onChange(event : any, applicant : any) {
    console.log(applicant);
    if(event.target.checked) {
      this.mailArray.push(applicant.registration_id)
    }
    else {
      console.log(this.mailArray.indexOf(applicant.registration_id))
      this.mailArray.splice(this.mailArray.indexOf(applicant.registration_id), 1)
    }
    console.log(this.mailArray)
  }
  onAllSelect(event : any) {
    if(event.target.checked) {
      this.mailArray = []
      this.users.forEach(element => {
        if(element.status == 'Completed') {
          this.mailArray.push(element.registration_id);
        }
      });
    }
    else {
      this.mailArray = []
    }
    console.log(this.mailArray)
  }
  // addMore() {
  //   this.uploadData.push({results_ids:'', result_pdf : ''})
  // }
  // file(event:any, index :any) {
  //   this.uploadData[index].result_pdf = event.target.files[0]
  //   console.log(this.uploadData)
  // }
  // submit() {
  //   let formdata = new FormData();
  //   let arrayId : any = [];
  //   let arrayPdf : any = [];
  //   this.uploadData.forEach((element:any, index:any) => {
  //     formdata.append(`result[id][${index}]`, element.results_ids);
  //     formdata.append(`result[pdf][${index}]`, element.result_pdf);
  //   });
  //   this.apiService.submit(formdata).then((res:any) => {
  //     console.log(res);
  //   })
  // }

  sendEmail() {
    if(this.mailArray?.length > 0) {
      this.spinner.show();
      let object = {
        emails : this.mailArray
      }
      this.apiService.sendEmailForReport(object).then((res:any) => {
        this.spinner.hide();
        this.messageService.add({
          severity: 'success',
          detail: 'Report Send Successfully!',
        });
        // this.mailArray = [];
        console.log(res);
        
      })
    } 
    else {
      this.messageService.add({
        severity: 'error',
        detail: 'Please Select Applicant First!',
      });
    }
  }
  
  sendShortListedId() {
    if(this.mailArray?.length > 0) {
      this.spinner.show();
      let object = {
        shortlistedIds : this.mailArray
      }
      this.apiService.sendShortListedId(object).then((res:any) => {
        this.spinner.hide();
        this.messageService.add({
          severity: 'success',
          detail: res.message,
        });
        this.route.navigateByUrl('site-visit');
        // this.mailArray = [];
        console.log(res);
      })
    } 
    else {
      this.messageService.add({
        severity: 'error',
        detail: 'Please Select Applicant First!',
      });
    }
  }
}
