<!-- <ngx-spinner name="sp5" [fullScreen]="true" type="square-jelly-box" size="medium"></ngx-spinner> -->
<p-toast [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display:
  'flex', 'justify-content': 'center', 'align-items': 'center',
  'flex-direction': 'column'}}">
</p-toast>
<!-- <p-confirmDialog header="Confirmation" [style]="{width: '50vw'}" [baseZIndex]="10000"
  rejectButtonStyleClass="p-button-text"> -->
<!-- </p-confirmDialog> -->
<p-confirmDialog #cd [style]="{width: '30vw'}" [breakpoints]="{'960px':
  '92vw'}">
  <ng-template pTemplate="header">
    <h5>Confirmation</h5>
    <button pButton pRipple type="button" icon="pi pi-times" (click)="cd.close()"
      class="p-button-rounded p-button-secondary p-button-text"></button>
  </ng-template>
  <ng-template pTemplate="footer">
    <!-- <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button> -->
    <!-- <p-button type="button" pButton icon="pi pi-check" styleClass="p-button-text" label="Yes"
      (click)="cd.accept()"></p-button> -->
    <p-button (click)="cd.accept()" icon="pi pi-check" label="Yes" styleClass="p-button-text"></p-button>
  </ng-template>
</p-confirmDialog>

<p-dialog header="Award Registration" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
    <h6 class="m-0">Thank You for your participation in UCCI Excellence Awards -2024. <strong>The application process is now closed.</strong> We shall get back to you shortly with results/queries.</h6>
        <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="displayModal = false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
</p-dialog>
<app-dashboard-sidemenu *ngIf="!previewCheck"></app-dashboard-sidemenu>

<!-- ********************** Main Content ******************** -->

<div class="main-content d-flex flex-column" *ngIf="!previewCheck">

  <!-- <app-dashboard-navbar></app-dashboard-navbar> -->
  <app-navbar-style-one></app-navbar-style-one>

  <!-- ********************** Breadcrumb Area  ******************** -->

  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
    <h1>{{Content.title}}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <!-- <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li> -->
      <li class="item">{{Content.title}}</li>
    </ol>
  </div>

  <!-- ********************** End Breadcrumb Area  ******************** -->

  <!-- ********************** Add Master Form Area  ******************** -->
  <div class="Master-view-box">
    <div class="theader">
      <!-- <ng-template pTemplate="caption"> -->
      <div class="d-flex search-bar">
        <span class="p-input-icon-left ml-auto mr-2">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="onSearch('dt1', $event.target.value)" placeholder="Search keyword"
            [(ngModel)]="filterVal" />
        </span>
        <p-button type="button" class="ml-2" (click)="reset()" [(ngModel)]="filterVal" icon="bx
          bx-reset"></p-button>
      </div>
      <div class="d-flex btn-box" *ngIf="localStorage?.role=='Admin'">
        <div class="d-flex">
          <div class="add-listings-btn mr-2">
            <button type="button" (click)="bulkApproval()"> Approve</button>
          </div>
          <div class="add-listings-btn">
            <button type="button" (click)="bulkReject()"> Reject</button>
          </div>
        </div>
        <div class="add-listings-btn ml-2">
          <!-- <button type="button" label="Send Email" (click)="showFormDialog('bulk', 'bulk')">Send Email</button> -->
          <button type="button" (click)="sendEmail('bulk', 'bulk')"> Send Email</button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12">
        <!-- *********************************************for user role table************************************************ -->
        <ng-container *ngIf="localStorage.role == 'User'">
          <p-table #dt1 [value]="users" sortField="company_name" rowGroupMode="subheader" sortMode="single"
            groupRowsBy="company_name" [paginator]="true" dataKey="company_name" [rows]="10"
            [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" responsiveLayout="scroll"
            [loading]="loading" currentPageReportTemplate="Showing {first} to {last} of
            {totalRecords} entries" [globalFilterFields]="['name', 'status',
            'company_name',
            'award.award_name', 'category']">
            <ng-template pTemplate="header" style="min-height:
              500px;">
              <tr>
                <th style="min-width: 100px; text-align: center; white-space:
                  no-wrap;">S. No.</th>
                <th *ngIf="localStorage?.role=='Admin'">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th style="max-width: 170px; width: 170px; text-align: center;
                  white-space: no-wrap;">
                  {{localStorage?.role=='Admin' ? 'Action' : 'View'}}
                  {{localStorage?.role=='Admin' ? 'Action' : 'Edit'}}
                </th>
                <th style="max-width: 150px; width: 150px; white-space:
                  no-wrap;">Status</th>
                <th style="min-width: 150px; white-space: no-wrap;">Award Name</th>
                <th style="min-width: 150px; white-space: no-wrap;">Classification</th>
                <th style="min-width: 150px; white-space: no-wrap;">Contact
                  Person</th>
                <!-- <th style="min-width: 200px; white-space: no-wrap;">Organization
                  Name</th> -->
                <th style="min-width: 130px; white-space: no-wrap;" *ngIf="localStorage?.role=='Admin'">Email</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="groupheader" let-item let-rowIndex="rowIndex" let-expanded="expanded">
              <tr>
                <td colspan="7">
                  <div style="display: flex; align-items:
                    center; font-size: 13px;">
                    <button type="button" pButton pRipple class="p-button-text p-button-rounded
                      p-button-plain p-mr-2" [icon]="expanded
                      ? 'pi pi-chevron-down' :
                      'pi pi-chevron-right'" [pRowToggler]="item"></button>
                    <span class="p-text-bold p-ml-2">{{item.company_name}}</span>
                  </div>
                </td>
              </tr>
            </ng-template>

            <ng-template pTemplate="rowexpansion" let-item let-i="rowIndex">
              <tr class="p-selectable-row">
                <td style="text-align: center;">{{i+1}}</td>
                <td *ngIf="localStorage?.role=='Admin'">
                  <p-tableCheckbox [value]="item">
                  </p-tableCheckbox>
                </td>
                <td style="text-align: center;">
                  <button pButton pRipple class="p-button-rounded
                    p-button-text
                    p-button-info" (click)="onViewPress(item)" pTooltip="View" tooltipPosition="bottom">
                    <i class="far fa-eye"></i>
                  </button>
                  <button pButton pRipple icon="bx bx-check"
                    class="p-button-rounded p-button-success p-button-text mr-2"
                    (click)="onAprrovPress(item, 'approve')" [disabled]="item.status !== 'Pending'"
                    *ngIf="localStorage?.role=='Admin'"></button>
                  <button pButton pRipple icon="bx bx-x" class="p-button-rounded
                    p-button-danger p-button-text mr-2" (click)="onRejectPress(item, 'reject')"
                    [disabled]="item.status !== 'Pending'" *ngIf="localStorage?.role=='Admin'"></button>
                </td>
                <td class="status">
                  <span class="in-progress" *ngIf="item.status == 'Pending'">
                    {{item.status}}
                  </span>
                  <span class="in-progress" *ngIf="item.status == null">
                    Pending
                  </span>
                  <span class="approved" *ngIf="item.status == 'Approved'">
                    {{item.status}}
                  </span>
                  <span class="rejected" *ngIf="item.status == 'Rejected'">
                    {{item.status}}
                  </span>
                </td>
                <td tooltipPosition="bottom" pTooltip="{{item.award.award_name}}">
                  {{item.award.award_name}}</td>
                <td>{{item.category}}</td>
                <td>{{item.name}}</td>
                <!-- <td>{{item.company_name}}</td> -->
                <td *ngIf="localStorage?.role=='Admin'">
                  <div class="add-listings-btn">
                    <button type="button" (click)="sendEmail('single', item)" [disabled]="item.status === 'Pending' || item.status ===
                      'In Progress'"><span class="bx bx-send"></span>
                      Send</button>
                      <!-- <button type="button" [disabled]="item.status === 'Pending' || item.status ===
                      'In Progress'" pButton label="Send" (click)="showFormDialog()" class="p-button"><span class="bx bx-send"></span>
                      </button> -->
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5">No data found.</td>
              </tr>
            </ng-template>
            
          </p-table>
        </ng-container>
        <!-- ***************************************************for admin role table********************************************************* -->
        <ng-container *ngIf="localStorage.role == 'Admin'">
          <p-table #dt1 [value]="users" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
            [(selection)]="selectedUser" styleClass="p-datatable-sm
            p-datatable-customers" [rowsPerPageOptions]="[10,25,50]" editMode="row" responsiveLayout="scroll"
            [loading]="loading" [paginator]="true" currentPageReportTemplate="Showing {first} to
            {last} of {totalRecords} entries" [globalFilterFields]="['name', 'status', 'company_name',
            'award.award_name', 'category']">
            <ng-template pTemplate="header" style="min-height: 500px;">
              <tr>
                <th style="min-width: 100px; text-align: center; white-space:
                  no-wrap;">S. No.</th>
                <th *ngIf="localStorage?.role=='Admin'">
                  <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>
                <th style="max-width: 170px; width: 170px; text-align: center;
                  white-space: no-wrap;">
                  {{localStorage?.role=='Admin' ? 'Action' : 'View'}}
                </th>
                <th style="max-width: 150px; width: 150px; white-space:
                  no-wrap;">Status</th>
                <th style="min-width: 150px; white-space: no-wrap;">Contact
                  Person</th>
                <th style="min-width: 200px; white-space: no-wrap;">Organization
                  Name</th>
                <th style="min-width: 150px; white-space: no-wrap;">Award Name</th>
                <th style="min-width: 150px; white-space: no-wrap;">Category</th>
                <th style="min-width: 130px; white-space: no-wrap;" *ngIf="localStorage?.role=='Admin'">Email</th>
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item let-i="rowIndex">
              <tr class="p-selectable-row">
                <td style="text-align: center;">{{i+1}}</td>
                <td *ngIf="localStorage?.role=='Admin'">
                  <p-tableCheckbox [value]="item">
                  </p-tableCheckbox>
                </td>
                <td style="text-align: center;">
                  <button pButton pRipple class="p-button-rounded
                    p-button-text
                    p-button-info" (click)="onViewPress(item)" pTooltip="View" tooltipPosition="bottom">
                    <i class="far fa-eye"></i>
                  </button>
                  <button pButton pRipple class="p-button-rounded
                    p-button-text
                    p-button-info" (click)="onEditPress(item)" pTooltip="Edit" tooltipPosition="bottom">
                    <i class="far fa-pencil"></i>
                 </button>
                  <button pButton pRipple icon="bx bx-check"
                    class="p-button-rounded p-button-success p-button-text mr-2" pTooltip="Approve"
                    (click)="onAprrovPress(item, 'approve')" [disabled]="item.status !== 'Pending'"
                    *ngIf="localStorage?.role=='Admin'"></button>
                  <button pButton pRipple icon="bx bx-x" class="p-button-rounded
                    p-button-danger p-button-text mr-2" (click)="onRejectPress(item, 'reject')" pTooltip="Reject"
                    [disabled]="item.status !== 'Pending'" *ngIf="localStorage?.role=='Admin'"></button>
                </td>
                <td class="status">
                  <span class="pending" *ngIf="item.status == 'Pending'">
                    {{item.status}}
                  </span>
                  <span class="pending" *ngIf="item.status == null">
                    Pending
                  </span>
                  <span class="approved" *ngIf="item.status == 'Approved'">
                    {{item.status}}
                  </span>
                  <span class="rejected" *ngIf="item.status == 'Rejected'">
                    {{item.status}}
                  </span>
                </td>
                <td>{{item.name}}</td>
                <td>{{item.company_name}}</td>
                <td tooltipPosition="bottom" pTooltip="{{item.award.award_name}}">
                  {{item.award.award_name.slice(0,20)}}</td>
                <td>{{item.category}}</td>
                <td *ngIf="localStorage?.role=='Admin'">
                  <div class="add-listings-btn">
                    <button type="button" (click)="sendEmail('single', item)" [disabled]="item.status === 'Pending' || item.status ===
                      'In Progress'"><span class="bx bx-send"></span>
                      Send</button>
                      <!-- <button type="button" [disabled]="item.status === 'Pending' || item.status ===
                      'In Progress'" pButton label="Send" (click)="showFormDialog('single', item)" class="p-button"><span class="bx bx-send"></span>
                      </button> -->
                  </div>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5">No data found.</td>
              </tr>
            </ng-template>
           
          </p-table>
        </ng-container>
        <!-- <p-dialog p-dialog header="Send Email" [modal]="true" [style]="{width:
          '40vw'}" [breakpoints]="{'850px' : '90vw'}" [draggable]="false" [resizable]="false" [(visible)]="displayForm">
          <form [formGroup]="notificationForm" (ngSubmit)="sendMessage(notification)" #notification="ngForm">
            <div class="form-group">
                <label style="margin-left: -6%; font-size: medium;">Subject</label>
                <input type="text" required formControlName="filter_name" placeholder="Subject"
                    class="form-control mb-2" [class.is-invalid]="(notification.submitted &&
                    notificationForm.get('filter_name')?.invalid) ||
                    notificationForm.get('filter_name')?.invalid &&
                    notificationForm.get('filter_name').touched">
                <div *ngIf="f.filter_name.errors?.required" class="alert alert-danger">
                    Subject is required
                </div>
                <label  style="margin-left: -6%; font-size: medium;">Message</label>
                <textarea style="min-height : 100px;" required formControlName="message" type="text" placeholder="Message"
                    class="form-control" [class.is-invalid]="(notification.submitted &&
                    notificationForm.get('message')?.invalid) ||
                    notificationForm.get('message')?.invalid &&
                    notificationForm.get('message').touched"></textarea>
                    <div *ngIf="f.message.errors?.required" class="alert alert-danger">
                      Message is required
                  </div>
            </div>
            <div class="add-listings-btn">
                <button type="submit" class="button">Send</button>
            </div>
        </form>
        </p-dialog> -->
        <ng-container *ngIf="localStorage.role == 'Auditor'">

          <p-table *ngIf="!previewCheck" #dt1 [value]="users" sortField="award_category" sortMode="single" dataKey="award_category"
            rowGroupMode="subheader" groupRowsBy="award_category" [rows]="10" [showCurrentPageReport]="true" styleClass="p-datatable-sm
            p-datatable-customers" editMode="row" responsiveLayout="scroll" [globalFilterFields]="['company_name']">
            <ng-template pTemplate="header">
              <tr>
                <th style="width:20%">S No.</th>
                <th style="width:20%">Company Name</th>
                <th style="width:20%">Award Name</th>
                <th style="width:20%">Status</th>
                <th style="width:20%">Export</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="groupheader" let-customer let-rowIndex="rowIndex" let-expanded="expanded">
              <tr>
                <td colspan="4">
                  <button type="button" pButton pRipple [pRowToggler]="customer"
                    class="p-button-text p-button-rounded p-button-plain mr-2"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                  <span class="font-bold ml-2" style="padding: 8px;display:inline-block;">{{customer?.award_category}}</span>
                </td>
                <td colspan="1">
                  <button class="p-button" pTooltip="Excel" (click)="downloadXL(customer?.award_category)"><i class="pi pi-file-excel" style="margin-right: 5px;"></i>Excel</button>
                  <!-- <a [href]="filePath" download>download</a> -->
                </td>
              </tr>
            </ng-template>
            <!-- <ng-template pTemplate="groupfooter" let-customer>
              <tr class="p-rowgroup-footer">
                <td colspan="4" style="text-align: right">Total Customers</td>
                <td>{{calculateCustomerTotal(customer.representative.name)}}</td>
              </tr>
            </ng-template> -->
            <ng-template pTemplate="rowexpansion" let-customer let-i="rowIndex">
              <tr>
                <td>
                  {{i+1}}
                </td>
                <td>
                  {{customer?.company_name}}
                </td>
                <td>
                  {{customer?.award_name}}
                </td>
                <td class="status">
                  <span class="pending" *ngIf="customer.status == ''">
                    Pending
                  </span>
                  <span class="pending" *ngIf="customer.status == null">
                    Pending
                  </span>
                  <span class="in-progress" *ngIf="customer.status == 'In Progress'">
                    In Progress
                  </span>
                  <span class="approved" *ngIf="customer.status == 'Completed'">
                    Completed
                  </span>
                </td>
                <td>
                  <button class="p-button" pTooltip="PDF" (click)="downloadPDF(customer)"><i class="pi pi-file-pdf" style="margin-right: 5px;"></i> PDF</button>
                </td>
              </tr>
            </ng-template>
          </p-table>

        </ng-container>
      </div>
    </div>
  </div>

  <app-copyrights></app-copyrights>
</div>


<p-dialog header="User Deatils" [(visible)]="displayMaximizable" [modal]="true" [style]="{ width: '90vw' }"
  [breakpoints]="{'960px': '75vw'}" [draggable]="false" [resizable]="false">
  <!-- Start -->
  <ng-container *ngFor="let data of details">
    <div class="row">
      <div class="col-lg-12">
        <div class="my-profile-box">
          <!-- <h3>User Details</h3> -->
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>Contact Person :</label>
                <input readonly class="form-control" [value]="data?.name |
                  titlecase">
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>Organization Name (Legal
                  Name) :</label>
                <input readonly class="form-control" [value]="data?.company_name
                  | titlecase">
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>Mobile No. :</label>
                <input readonly class="form-control" [value]="data?.mobile_no">
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>Email :</label>
                <input readonly class="form-control" [value]="data?.email">
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>PAN:</label>
                <input readonly class="form-control" [value]="data?.company_PAN">
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>GST No. :</label>
                <input readonly class="form-control" [value]="data?.company_GST
                  ? data?.company_GST : 'Not Available'">
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>Address :</label>
                <input readonly class="form-control" [value]="data?.company_address | titlecase" />
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>City :</label>
                <input readonly class="form-control" [value]="data?.company_city
                  | titlecase">
              </div>
            </div>
            <!-- <div class="col-lg-6 col-md-12">
                <div class="form-group">
                  <label>Name :</label>
                  <input readonly class="form-control" [value]="data?.company_name | titlecase ">
                </div>
              </div> -->
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>State :</label>
                <input readonly class="form-control" [value]="data?.company_state | titlecase">
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>Pincode :</label>
                <input readonly class="form-control" [value]="data?.company_pincode">
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>Award Type :</label>

                <input readonly class="form-control" [value]="data?.award_type
                  ">
              </div>
            </div>
            <div class=" col-lg-6 col-md-12">
              <div class="form-group">
                <label>Classification Industry:</label>
                <input readonly class="form-control" [value]="data?.classification?.name | titlecase">
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>Turnover in INR ({{preYear}}-{{date | date: 'yy'}}) :</label>
                <input readonly class="form-control" value="{{data?.turnover |
                  indianCurrency}}">
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>Award Name :</label>
                <input readonly class="form-control" [value]="data?.award?.award_name">
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>Achievements :</label>
                <input readonly class="form-control" [value]="data?.achievements">
              </div>
            </div>
            <div class="col-lg-6 col-md-12">
              <div class="form-group">
                <label>Strengths :</label>
                <input readonly class="form-control" [value]="data?.reason">
              </div>
            </div>
            <!-- <div class="col-lg-6 col-md-12">
                <div class="form-group">
                  <label>Name :</label>
                  <input readonly class="form-control" [value]="data?.company_name">
                </div>
              </div> -->
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</p-dialog>

<!-- edit register -->
<p-dialog [(visible)]="displayMaximizableEdit" [modal]="true" [style]="{width: '90vw'}" [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header">
    <h3>Edit User</h3>
  </ng-template>
  
  <form [formGroup]="registerForm" (ngSubmit)="onSubmitEdit()" style="width: 100%;">
   
    <div class="row">
      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label>Contact Person :<span class="requierdSign">*</span></label>
          <input class="form-control" formControlName="name" placeholder="Contact Person name">
          <small class="text-danger" *ngIf="registerForm.get('name').invalid && (registerForm.get('name').dirty || registerForm.get('name').touched)">
            Contact Person is required.
          </small>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label>Organization  (Legal Name) :<span class="requierdSign">*</span></label>
          <input class="form-control" formControlName="company_name" placeholder="Organization Name">
          <small class="text-danger" *ngIf="registerForm.get('company_name').invalid && (registerForm.get('company_name').dirty || registerForm.get('company_name').touched)">
            Organization Name is required.
          </small>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label>Mobile No. :<span class="requierdSign">*</span></label>
          <input class="form-control" formControlName="mobile_no" placeholder="Mobile Number">
          <small class="text-danger" *ngIf="registerForm.get('mobile_no').invalid && (registerForm.get('mobile_no').dirty || registerForm.get('mobile_no').touched)">
            Please enter a valid mobile number.
          </small>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label>Email :<span class="requierdSign">*</span></label>
          <input class="form-control" formControlName="email" placeholder="Email">
          <small class="text-danger" *ngIf="registerForm.get('email').invalid && (registerForm.get('email').dirty || registerForm.get('email').touched)">
            Please enter a valid email address.
          </small>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label>PAN :<span class="requierdSign">*</span></label>
          <input class="form-control" formControlName="company_PAN" placeholder="PAN">
          <small class="text-danger" *ngIf="registerForm.get('company_PAN').invalid && (registerForm.get('company_PAN').dirty || registerForm.get('company_PAN').touched)">
            Please enter a valid PAN.
          </small>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label>GST Registration Type :<span class="requierdSign">*</span></label>
          <select formControlName="gst_type" (change)="onGstTypeSelection($event.target.value)">
            <option [value]="" disabled>Select GST Type</option>
            <option value="registered">Registered</option>
            <option value="nonRegistered">Non Registered</option>
          </select>
          <small class="text-danger" *ngIf="registerForm.get('gst_type').invalid && (registerForm.get('gst_type').dirty || registerForm.get('gst_type').touched)">
            GST Registration Type is required.
          </small>
        </div>
      </div>

      <div class="col-lg-6 col-md-12" *ngIf="registerForm.get('gst_type').value === 'registered'">
        <div class="form-group">
          <label>GST Number :<span class="requierdSign">*</span></label>
          <input class="form-control" formControlName="company_GST" placeholder="GST Number">
          <small class="text-danger" *ngIf="registerForm.get('company_GST').invalid && (registerForm.get('company_GST').dirty || registerForm.get('company_GST').touched)">
            Please enter a valid GST Number.
          </small>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label>Address Line 1 :<span class="requierdSign">*</span></label>
          <textarea class="form-control" formControlName="company_address" placeholder="Enter Address Line 1"></textarea>
          <small class="text-danger" *ngIf="registerForm.get('company_address').invalid && (registerForm.get('company_address').dirty || registerForm.get('company_address').touched)">
            Address Line 1 is required.
          </small>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label>Address Line 2 :</label>
          <textarea class="form-control" formControlName="address_line2" placeholder="Enter Address Line 2"></textarea>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label>State :<span class="requierdSign">*</span></label>
          <p-dropdown [options]="state" formControlName="company_state" optionLabel="name" [filter]="true" filterBy="name" [showClear]="true" placeholder="Select State">
            <ng-template pTemplate="selectedItem">
              <div class="state-item state-item-value" *ngIf="registerForm.value.company_state?.name">
                <div>{{registerForm.value.company_state.name}}</div>
              </div>
            </ng-template>
            <ng-template let-item pTemplate="item">
              <div class="state-item">
                <div>{{item.name}}</div>
              </div>
            </ng-template>
          </p-dropdown>
          <small class="text-danger" *ngIf="registerForm.get('company_state').invalid && (registerForm.get('company_state').dirty || registerForm.get('company_state').touched)">
            State is required.
          </small>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label>City :<span class="requierdSign">*</span></label>
          <input class="form-control" formControlName="company_city" placeholder="City">
          <small class="text-danger" *ngIf="registerForm.get('company_city').invalid && (registerForm.get('company_city').dirty || registerForm.get('company_city').touched)">
            City is required.
          </small>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label>Pincode :<span class="requierdSign">*</span></label>
          <input class="form-control" formControlName="company_pincode" placeholder="Pincode">
          <small class="text-danger" *ngIf="registerForm.get('company_pincode').invalid && (registerForm.get('company_pincode').dirty || registerForm.get('company_pincode').touched)">
            Please enter a valid Pincode.
          </small>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label>Award Type :<span class="requierdSign">*</span></label>
          <select formControlName="award_type" (change)="onAwardTypeSelection($event.target.value)">
            <option value="">Select Award Type</option>
            <option value="excellence">Excellence Award</option>
            <option value="csr">CSR Award</option>
            <option value="se">Social Enterprises Award</option>
          </select>
          <small class="text-danger" *ngIf="registerForm.get('award_type').invalid && (registerForm.get('award_type').dirty || registerForm.get('award_type').touched)">
            Award Type is required.
          </small>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label>Industry Classification :<span class="requierdSign">*</span></label>
          <p-dropdown [options]="classification | groupByPipe : 'category'" formControlName="classification_id" placeholder="Select Industry Classification" [group]="true">
            <ng-template let-item pTemplate="selectedItem">
              {{item.name}}
            </ng-template>
            <ng-template let-group pTemplate="group">
              <div class="p-d-flex p-ai-center">
                <span>{{group.name}}</span>
              </div>
            </ng-template>
            <ng-template let-item pTemplate="item">
              {{item.name}}
            </ng-template>
          </p-dropdown>
          <small class="text-danger" *ngIf="registerForm.get('classification_id').invalid && (registerForm.get('classification_id').dirty || registerForm.get('classification_id').touched)">
            Industry Classification is required.
          </small>
        </div>
      </div>

      <div class="col-lg-6 col-md-12">
        <div class="form-group">
          <label>Turnover in INR ({{preYear}}-{{date | date: 'yy'}}) :<span class="requierdSign">*</span></label>
          <p-inputNumber inputId="locale-indian" mode="decimal" locale="en-IN" prefix="₹" placeholder="Turnover" formControlName="turnover">
          </p-inputNumber>
          <small class="text-danger" *ngIf="registerForm.get('turnover').invalid && (registerForm.get('turnover').dirty || registerForm.get('turnover').touched)">
            Turnover is required.
          </small>
        </div>
      </div>

      <div class="col-lg-12 col-md-12">
        <div class="form-group">
          <label>Mention 2-3 Key Achievements In Terms of Business Operations / Innovation Etc Which Emphasize Why Your Organization Qualifies For A Consideration For Award(s) :<span class="requierdSign">*</span></label>
          <textarea class="form-control" formControlName="achievements" placeholder="Enter Achievement" maxlength="400"></textarea>
          <small class="text-danger" *ngIf="registerForm.get('achievements').invalid && (registerForm.get('achievements').dirty || registerForm.get('achievements').touched)">
            Key Achievements are required.
          </small>
        </div>
      </div>

      <div class="col-lg-12 col-md-12">
        <div class="form-group">
          <label>Mention upto 3 Key Strengths / USPs ( Unique Features) of Your Enterprise :<span class="requierdSign">*</span></label>
          <textarea class="form-control" formControlName="reason" placeholder="Enter Strength" maxlength="400"></textarea>
          <small class="text-danger" *ngIf="registerForm.get('reason').invalid && (registerForm.get('reason').dirty || registerForm.get('reason').touched)">
            Key Strengths are required.
          </small>
        </div>
      </div>
      <div class="col-lg-12 col-md-12">
        <div class="form-group">
          <label>Reason why change this :<span class="requierdSign">*</span></label>
          <textarea class="form-control" formControlName="remark" placeholder="Enter Reason why change this" maxlength="5000"></textarea>
          <small class="text-danger" *ngIf="registerForm.get('remark').invalid && (registerForm.get('remark').dirty || registerForm.get('remark').touched)">
            Reason for change is required.
          </small>
        </div>
      </div>
    </div>

    <div class="col-lg-2 col-md-12">
        <button type="submit" class="p-button custom-color-button" >Save</button>
    </div>
  </form>
</p-dialog>

<!-- end register -->
<form [formGroup]="statusForm" #form="ngForm" (ngSubmit)="statusUpdate(form)">
  <p-dialog header="Rejection" [(visible)]="statusModal" [modal]="true" [style]="{width: '50%'}" [draggable]="false"
    [resizable]="false">

    <div class="row mt-1">
      <div class="col-lg-12 col-xs-12">
        <div class="form-group">Reason<span style="color: red;" *ngIf="status
            === 'reject'">*</span>:
          <textarea type="text" class="form-control" placeholder="Reason" formControlName="remark" required
            style="margin-top: 5px;" [class.is-invalid]="(form.submitted &&
            statusForm.get('remark')?.invalid) ||
            statusForm.get('remark')?.invalid &&
            statusForm.get('remark').touched"></textarea>
          <div class="invalid-feedback">Reason is required</div>
        </div>
      </div>
    </div>

    <ng-template pTemplate="footer">
      <p-button type="submit" icon="pi pi-check" label="Reject" styleClass="p-button-text"></p-button>
      <!-- <button type="submit">Reject</button> -->
      <!-- <button type="submit" pButton icon="pi pi-check" label="Reject"></button> -->
    </ng-template>
  </p-dialog>
</form>

<ng-container *ngIf="previewCheck">
  <!-- {{sections | json}} -->
  <div class="add-listings-box" *ngIf="sections.length > 0">
      <div class="heading">
          <img src="assets/img/ucci-awards-logo.png" alt="">
          <h2>{{title}} {{nextYear}} / {{hindiTitle}} {{nextYear}}</h2>
          <!-- <p> Please complete the form below and provide details of your business and company.
              कृपया निम्नलिखित फार्म को पूरा भरें तथा अपने व्यवसाय एवं कम्पनी के विषय में जानकारी देवे:</p> -->
      </div>

      <div class="d-flex justify-content-between align-items-center position-sticky button_div"
          style="top: 0;background:white;padding:20px 0;">
          <p-button type="button" #back_btn class="test " (click)="previewCheck = false" icon="bx bx-arrow-back"
              label="" iconPos="left">
          </p-button>
          <button (click)="getPDF()" class="pdf_btn">Save as PDF</button>
      </div>
      <br>
      <div class="preview">
          <ng-container *ngFor="let section of sections">
              <ng-container *ngFor="let control of section.controls; let i = index">
                  <div *ngIf="i === 0" class="section-heading">
                      <span>{{control.label}}</span>
                  </div>
                  <div class="group">
                      <label for="" *ngIf="i !== 0" class="label">{{control.label}}</label>
                      <ng-container *ngIf="control.name !='table'">
                          <div class="value" *ngIf="(control.name !== 'label' && control.name !== 'file')">
                              <!-- <i class="bx bx-circle" style="font-size: 8px;
                              color: black;
                              background: black;
                              border-radius: 25px;"></i> -->
                              <i class="fa fa-thin fa-arrow-right mt-1"></i>
                              <span>{{control.value != '' ? control.value : '-'}}</span>
                          </div>
                          <div class="value color" *ngIf="control.name === 'file'">
                              <ng-container *ngIf="control.value != '' ">
                                  <i class="fa fa-thin fa-arrow-right mt-1"></i>
                                  <!-- <span><a href="{{control.value}}" target="_blank">{{control.value}}</a></span> -->
                                  <span><a href="{{control.value}}" target="_blank">View Document</a></span>
                              </ng-container>
                              <ng-container *ngIf="control.value == '' ">
                                  <i class="fa fa-thin fa-arrow-right mt-1"></i>
                                  <span>-</span>
                              </ng-container>
                          </div>
                      </ng-container>
                  </div>
                  <ng-contaier *ngIf="control.name =='table'">
                      <table>
                          <thead>
                              <tr>
                                  <ng-container *ngFor="let colHead of control.columns">
                                      <th class="table-label">
                                          {{colHead.label}}
                                      </th>
                                  </ng-container>
                              </tr>
                          </thead>
                          <tbody>
                              <ng-container *ngFor="let tableRow of control.rows">
                                  <tr>
                                      <ng-container *ngFor="let tableCol of tableRow">
                                          <!-- {{tableCol | json}} -->
                                          <td *ngIf="(tableCol.name !== 'label' && tableCol.name !== 'file')">
                                              <span class="value" *ngIf="tableCol.value != ''">{{
                                                  tableCol.value }}</span>
                                              <span class="value" *ngIf="tableCol.value == ''"
                                                  style="display: flex; justify-content: center; align-items: center;">-</span>

                                          </td>

                                          <td *ngIf="(tableCol.name == 'label')" style="background-color: #f9f9f9;">
                                              <label for="" class="table-label">{{tableCol.label}}</label>
                                          </td>
                                          <td *ngIf="tableCol.name == 'file'" style="background-color: #f9f9f9;">
                                              <ng-container *ngIf="tableCol.value !== ''">
                                                  <label for="" class="value"
                                                      style="justify-content: center;">Uploaded</label>
                                                  <!-- <label for="" class="value color"><a href="{{tableCol.value}}"
                                                          target="_blank">{{tableCol.value}}</a></label> -->
                                              </ng-container>
                                              <ng-container *ngIf="tableCol.value === ''">
                                                  <label for="" class="value"
                                                      style="justify-content: center;">-</label>
                                              </ng-container>
                                          </td>
                                      </ng-container>
                                  </tr>
                              </ng-container>
                          </tbody>
                      </table>
                  </ng-contaier>
                  <!-- <br> -->
              </ng-container>
          </ng-container>
      </div>
  </div>
</ng-container>
