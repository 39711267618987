import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { CommonClass } from 'src/app/common';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilderService } from 'src/app/services/form-builder.service';
import { EMPTY } from 'rxjs';
import { from } from 'rxjs';
// import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
declare const pdfjsLib: any;

@Component({
  selector: 'app-site-visit',
  templateUrl: './site-visit.component.html',
  styleUrls: ['./site-visit.component.scss'],
})
export class SiteVisitComponent implements OnInit {
  
  localStorage: any;
  @ViewChild('dt1') dt1: Table;
  breadcrumb: { title: string; subTitle: string }[];
  users: any;
  filterVal: string;
  preYear: number;
  currentYear: any;
  date: Date = new Date();
  award_category: any;
  company_name: any;
  sections: any = [];
  siteVisitData: any = [];
  nextYear: number;
  regId = [
    33, 102, 13, 112, 50, 91, 23, 9, 25, 59, 149, 89, 43, 61, 24, 96, 79, 101,
    38, 86, 81, 100, 14,
  ];
  title: any;
  hindiTitle: string;
  award_name: any;
  parsedSiteJson: any;
  allControlsData: any = [];
  matchingLabelsAndValues: any = [];
  dataBoolean: boolean = false;
  companySummary: any;
  contactPersonName: any;
  companyAddress: any;
  companyNumber: any;
  juryLabelValues: any = [];
  juryControlsData: any = [];
  filteredControls: any[];
  // pdfPages: SafeResourceUrl[] = [];
  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private commonFunction: CommonClass,
    private confirmationService: ConfirmationService,
    private _formBuilder: FormBuilderService,
    // private sanitizer: DomSanitizer
  ) {}

  previewCheck: boolean = false;
  juryEvaluation: boolean = false;

  async ngOnInit() {
    this.localStorage = this.commonFunction.getLocalStorage();
    this.spinner.show();
    if (this.localStorage.role == 'Admin') {
      // this.getUsers();
      this.breadcrumb = [
        {
          title:
            this.localStorage.role == 'Admin'
              ? 'Shortlisted Applicants'
              : 'Registered Users',
          subTitle: 'Home',
        },
      ];
      await this._formBuilder.getAPI('response/allData').then((res: any) => {
        console.log(res);
        this.users = res?.data?.filter(
          (res: any) =>
            // this.regId.includes(res?.registration_id)
            res?.shortlisted == 1
        );
        console.log(this.users);
      });
    } else {
      console.log(this.users);
    }
    this.currentYear = this.date.getFullYear();
    this.preYear = this.currentYear - 1;
    this.spinner.hide();
    // setTimeout(() => {
    //   this.loadPdfPages();
    // }, 5000); 
  }

  // async downloadPDF(user: any) {
  //   this.spinner.show();
  //   this.apiService.getSiteVisitData(user.id).subscribe((res: any) => {
  //     console.log('getSiteVisitData: ', res);
  //     if (res?.data == null) {
  //       this.spinner.hide();
  //       // Display an alert message if data is null
  //       this.messageService.add({
  //         severity: 'error',
  //         summary: 'Error',
  //         detail: 'Site visit data is empty',
  //       });
  //     } else {
  //       this.spinner.hide();
  //       this.parsedSiteJson = JSON.parse(res?.data);
  //     }
  //   });

  //   console.log(user);
  //   await this._formBuilder
  //     .getAPI(
  //       'formWithData/' +
  //         user?.slug +
  //         '?id=' +
  //         user?.registration_id +
  //         '&user_id=' +
  //         user?.user_id
  //     )
  //     .then((res: any) => {
  //       console.log(res);
  //       // 
  //       this.award_category = user?.award_category;
  //       this.award_name = user?.award_name;
  //       this.company_name = user?.company_name;
  //       if (res) {
  //         if (res?.data[0]?.form_response || res?.data[0]?.form_json) {
  //           this.sections = JSON?.parse(
  //             JSON?.parse(
  //               res?.data[0]?.form_response ?? res?.data[0]?.form_json
  //             )
  //           );
  //         }
  //         this.title = res?.data[0]?.form_title;

  //         if (res?.data[0]?.form_title === 'Service Award Form') {
  //           this.hindiTitle = 'सेवा क्षेत्र पुरस्कार';
  //         } else if (res?.data[0]?.form_title === 'Manufacturing Award Form') {
  //           this.hindiTitle = 'विनिर्माण क्षेत्र पुरस्कार';
  //         } else if (res?.data[0]?.form_title === 'Social Award Form') {
  //           // 
  //           this.hindiTitle = 'सामाजिक उपक्रम पुरस्कार';
  //         } else if (res?.data[0]?.form_title === 'CSR Award Form') {
  //           this.hindiTitle = 'सामाजिक उत्तरदायित्व पुरस्कार';
  //         }
  //       }
  //     });

  //   // if (this.dataBoolean == true) {
  //     setTimeout(() => {
  //       for (const index in this.sections) {
  //         if (this.sections.hasOwnProperty(index)) {
  //           const controlsArray = this.sections[index].controls;
  //           if (controlsArray && Array.isArray(controlsArray)) {
  //             this.allControlsData.push(...controlsArray);
  //           }
  //         }
  //       }
  //       const VisitLabel = this.parsedSiteJson;
  //       const formResponses = this.sections;

  //       // Initialize an array to store matching labels and values
  //       // const matchingLabelsAndValues = [];

  //       // Iterate over formResponses and find matching labels
  //       for (let i = 0; i < this.allControlsData?.length; i++) {
  //         const siteVisitLabel = VisitLabel[i]?.label;
  //         const controls = this.allControlsData;

  //         // Check if controls is an array
  //         if (Array.isArray(controls)) {
  //           // Filter controls based on the matching label
  //           const matchingControls = controls.filter(
  //             (control) => control.label === siteVisitLabel
  //           );

  //           // Check if there are matching controls
  //           if (matchingControls.length > 0) {
  //             // Assuming there is at least one matching control
  //             const matchedControl = matchingControls[0];
  //             const matchedLabel = matchedControl?.label;
  //             const matchedValue = matchedControl?.value;

  //             // Store the matched label and value in the array
  //             if (matchedControl?.col !== '') {
  //               this.matchingLabelsAndValues.push({
  //                 label: matchedLabel,
  //                 value: matchedValue,
  //               });
  //             } else {
  //               this.matchingLabelsAndValues.push({
  //                 label: matchedLabel,
  //                 value: matchedValue,
  //                 col: matchedControl?.col,
  //               });
  //             }
  //           }
  //         }
  //       }

  //       console.log(
  //         'Matching Labels and Values:',
  //         this.matchingLabelsAndValues
  //       );
  //     }, 2000);
  //   // }else {
  //   //   this.messageService.add({
  //   //           severity: 'error',
  //   //           summary: 'error',
  //   //           detail:'Data is empty'
  //   //         });
  //   // }

  //   let date = new Date();

  //   let year = date.getFullYear();

  //   this.nextYear = year;
  //   this.previewCheck = true;
  //   this.spinner.hide();
  // }
  async downloadPDF(user: any) {
    this.matchingLabelsAndValues = [];
    this.allControlsData = [];
    try {
      this.spinner.show();

      // Convert the observables to promises using 'from' function
      const siteVisitData: any = await from(
        this.apiService.getSiteVisitData(user.id)
      ).toPromise();
      const formBuilderData = await from(
        this._formBuilder.getAPI(
          'formWithData/' +
            user?.slug +
            '?id=' +
            user?.registration_id +
            '&user_id=' +
            user?.user_id
        )
      ).toPromise();
      this.spinner.hide();

      // Check if siteVisitData.data is null
      if (siteVisitData?.data == null) {
        // Display an alert message if data is null
        // alert('Site visit data is empty')
        this.messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Site visit data is empty',
        });
        return; // Exit the function if siteVisitData.data is null
      }
      this.parsedSiteJson = JSON.parse(siteVisitData?.data);

      console.log(this.companySummary);
      // Process formBuilderData
      console.log(formBuilderData);
      this.award_category = user?.award_category;
      this.award_name = user?.award_name;
      this.company_name = user?.company_name;

      if (
        formBuilderData &&
        (formBuilderData?.data[0]?.form_response ||
          formBuilderData?.data[0]?.form_json)
      ) {
        this.sections = JSON?.parse(
          JSON?.parse(
            formBuilderData?.data[0]?.form_response ??
              formBuilderData?.data[0]?.form_json
          )
        );
      }

      this.title = formBuilderData?.data[0]?.form_title;
            // Assuming sections[1] is defined and is an object with a controls property
      const newControlsArray = this.sections[0].controls;

      // Debugging: Log the contents of the controls array
      console.log('Controls Array:', newControlsArray);
      
      // Labels to filter
      const labelsToFilter = [
        'Address of registered office / पंजीकृत कार्यालय का पता',
        'Name / नाम',
        'Mobile number / मोबाईल संख्या',
      ];
      
      // Function to extract alphanumeric content from a string
      const getAlphanumericContent = (text: string): string => text.replace(/[^a-zA-Z0-9]/g, '');
      
      // Filter and map to get 'value' of fields with specified 'label'
      const filteredValues = newControlsArray
        .filter((control) => {
          const controlLabelContent = getAlphanumericContent(control.label);
          return labelsToFilter.some((filterLabel) => {
            const filterLabelContent = getAlphanumericContent(filterLabel);
            return controlLabelContent.includes(filterLabelContent);
          });
        })
        .map((control) => control.value);
      
      // Debugging: Log the final filteredValues
      console.log('Filtered Values:', filteredValues);
      this.companyAddress = filteredValues[0]
      this.contactPersonName = filteredValues[1]
      this.companyNumber = filteredValues[2]

      const controlsArray = this.sections[1].controls;
     
      // Filter the array to get objects whose label starts with '2.1'
      const companySummary = controlsArray.filter((control) =>
        control.label.startsWith('2.1')
      );
      this.companySummary = companySummary[0].value;
      console.log(this.companySummary);
      // Now filteredControls contains the objects whose label starts with '2.1'
      console.log(companySummary);
      // Code for setting Hindi title based on form title...

      if (this.title === 'Service Award Form') {
        this.hindiTitle = 'सेवा क्षेत्र पुरस्कार';
      } else if (this.title === 'Manufacturing Award Form') {
        this.hindiTitle = 'विनिर्माण क्षेत्र पुरस्कार';
      } else if (this.title === 'Social Award Form') {
        // 
        this.hindiTitle = 'सामाजिक उपक्रम पुरस्कार';
      } else if (this.title === 'CSR Award Form') {
        this.hindiTitle = 'सामाजिक उत्तरदायित्व पुरस्कार';
      }
      for (const index in this.sections) {
        if (this.sections.hasOwnProperty(index)) {
          const controlsArray = this.sections[index].controls;
          if (controlsArray && Array.isArray(controlsArray)) {
            this.allControlsData.push(...controlsArray);
          }
        }
      }

      const VisitLabel = this.parsedSiteJson;
      const formResponses = this.sections;

      // Initialize an array to store matching labels and values
      // const matchingLabelsAndValues = [];

      // Iterate over formResponses and find matching labels
      for (let i = 0; i < this.allControlsData?.length; i++) {
        const siteVisitLabel = VisitLabel[i]?.label;
        const controls = this.allControlsData;

        // Check if controls is an array
        if (Array.isArray(controls)) {
          // Filter controls based on the matching label
          const matchingControls = controls.filter(
            (control) => control.label === siteVisitLabel
          );

          // Check if there are matching controls
          if (matchingControls.length > 0) {
            // Assuming there is at least one matching control
            const matchedControl = matchingControls[0];
            const matchedLabel = matchedControl?.label;
            const matchedValue = matchedControl?.value;

            // Store the matched label and value in the array
            // if (matchedControl?.columns !== '') {
            //   this.matchingLabelsAndValues.push({
            //     label: matchedLabel,
            //     value: matchedValue,
            //   });
            // } else {
            if (matchedControl?.rows && matchedControl.rows.length > 0) {
              const slicedRows = matchedControl.rows.map((innerArray) => {
                const lastIndex = innerArray.length - 1;
                return innerArray.slice(0, lastIndex); // Exclude the last index of each inner array
              });

              this.matchingLabelsAndValues.push({
                label: matchedLabel,
                value: matchedValue,
                columns: matchedControl.columns,
                rows: slicedRows,
              });
            } else {
              this.matchingLabelsAndValues.push({
                label: matchedLabel,
                value: matchedValue,
                columns: matchedControl?.columns,
                rows: matchedControl?.rows,
              });
            }

            // }
          }
        }
      }
   
      console.log('Matching Labels and Values:', this.matchingLabelsAndValues);

      let date = new Date();
      let year = date.getFullYear();
      this.nextYear = year;
      this.previewCheck = true;
    } catch (error) {
      // Handle errors if any
      console.error('Error:', error);
    } finally {
      this.spinner.hide();
    }
  }
  async downloadJuryEvaluation(user: any){
    
      this.juryLabelValues = [];
      this.juryControlsData = [];
      try {
        this.spinner.show();
  
        // Convert the observables to promises using 'from' function
        const siteVisitData: any = await from(
          this.apiService.getSiteVisitData(user.id)
        ).toPromise();
        const formBuilderData = await from(
          this._formBuilder.getAPI(
            'formWithData/' +
              user?.slug +
              '?id=' +
              user?.registration_id +
              '&user_id=' +
              user?.user_id
          )
        ).toPromise();
        this.spinner.hide();
  
        // Check if siteVisitData.data is null
        if (siteVisitData?.data == null) {
          // Display an alert message if data is null
          // alert('Site visit data is empty')
          this.messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Site visit data is empty',
          });
          return; // Exit the function if siteVisitData.data is null
        }
        this.parsedSiteJson = JSON.parse(siteVisitData?.data);
  
        console.log(this.companySummary);
        // Process formBuilderData
        console.log(formBuilderData);
        this.award_category = user?.award_category;
        this.award_name = user?.award_name;
        this.company_name = user?.company_name;
  
        if (
          formBuilderData &&
          (formBuilderData?.data[0]?.form_response ||
            formBuilderData?.data[0]?.form_json)
        ) {
          this.sections = JSON?.parse(
            JSON?.parse(
              formBuilderData?.data[0]?.form_response ??
                formBuilderData?.data[0]?.form_json
            )
          );
        }
  
        this.title = formBuilderData?.data[0]?.form_title;
                // Assuming sections[1] is defined and is an object with a controls property
        const newControlsArray = this.sections[0].controls;
  
        // Debugging: Log the contents of the controls array
        console.log('Controls Array:', newControlsArray);
        
        // Labels to filter
        const labelsToFilter = [
          'Address of registered office / पंजीकृत कार्यालय का पता',
          'Name / नाम',
          'Mobile number / मोबाईल संख्या',
        ];
        
        // Function to extract alphanumeric content from a string
        const getAlphanumericContent = (text: string): string => text.replace(/[^a-zA-Z0-9]/g, '');
        debugger;
        // Filter and map to get 'value' of fields with specified 'label'
        const filteredValues = newControlsArray
          .filter((control) => {
            const controlLabelContent = getAlphanumericContent(control.label);
            return labelsToFilter.some((filterLabel) => {
              const filterLabelContent = getAlphanumericContent(filterLabel);
              return controlLabelContent.includes(filterLabelContent);
            });
          })
          .map((control) => control.value);
        
        // Debugging: Log the final filteredValues
        console.log('Filtered Values:', filteredValues);
        this.companyAddress = filteredValues[0]
        this.contactPersonName = filteredValues[1]
        this.companyNumber = filteredValues[2]
  
        const controlsArray = this.sections[1].controls;
  
        // Filter the array to get objects whose label starts with '2.1'
        const companySummary = controlsArray.filter((control) =>
          control.label.startsWith('2.1')
        );
        this.companySummary = companySummary[0].value;
        console.log(this.companySummary);
        // Now filteredControls contains the objects whose label starts with '2.1'
        console.log(companySummary);
        // Code for setting Hindi title based on form title...
  
        if (this.title === 'Service Award Form') {
          this.hindiTitle = 'सेवा क्षेत्र पुरस्कार';
        } else if (this.title === 'Manufacturing Award Form') {
          this.hindiTitle = 'विनिर्माण क्षेत्र पुरस्कार';
        } else if (this.title === 'Social Award Form') {
          // 
          this.hindiTitle = 'सामाजिक उपक्रम पुरस्कार';
        } else if (this.title === 'CSR Award Form') {
          this.hindiTitle = 'सामाजिक उत्तरदायित्व पुरस्कार';
        }
        for (const index in this.sections) {
          if (this.sections.hasOwnProperty(index)) {
            const controlsArray = this.sections[index].controls;
            if (controlsArray && Array.isArray(controlsArray)) {
              this.juryControlsData.push(...controlsArray);
            }
          }
        }
  
        const VisitLabel = this.parsedSiteJson;
        const formResponses = this.sections;
  
        // Initialize an array to store matching labels and values
        // const juryLabelValues = [];
  
        // Iterate over formResponses and find matching labels
        for (let i = 0; i < this.juryControlsData?.length; i++) {
          const siteVisitLabel = VisitLabel[i]?.label;
          const controls = this.juryControlsData;
  
          // Check if controls is an array
          if (Array.isArray(controls)) {
            // Filter controls based on the matching label
            const matchingControls = controls.filter(
              (control) => control.label === siteVisitLabel
            );
          
            // Check if there are matching controls
            if (matchingControls.length > 0) {
              // Assuming there is at least one matching control
              const matchedControl = matchingControls[0];
              const matchedLabel = matchedControl?.label;
              const matchedValue = matchedControl?.value;
          
              // Check if the control has rows and columns
              if (matchedControl?.rows && matchedControl?.columns) {
                // Push the control with rows and columns
                this.juryLabelValues.push({
                  label: matchedLabel,
                  value: matchedValue,
                  columns: matchedControl.columns,
                  rows: matchedControl?.rows,
                  rowhead: matchedControl.rowHead,
                });
              } else {
                // Check if the control has an index object just after
                const nextControl = controls[controls.indexOf(matchedControl) + 1];
          
                // Check if the next control has columns and rows
                if (nextControl?.columns && nextControl?.rows) {
                  // Push the next control with columns and rows
                  this.juryLabelValues.push({
                    label: matchedLabel,
                    value: matchedValue,
                    columns: nextControl.columns,
                    rows: nextControl?.rows,
                    rowhead: nextControl.rowHead,
                  });
                } else {
                  // Push the control without rows and columns
                  this.juryLabelValues.push({
                    label: matchedLabel,
                    value: matchedValue,
                    columns: matchedControl?.columns,
                    rows: matchedControl?.rows,
                  });
                }
              }
            }
          }
          
        }
  
        console.log('Matching Labels and Values:', this.juryLabelValues);
  
        let date = new Date();
        let year = date.getFullYear();
        this.nextYear = year;
        this.previewCheck = true;
        this.juryEvaluation = true;
        // this.loadPdfPages();
      } catch (error) {
        // Handle errors if any
        console.error('Error:', error);
      } finally {
        this.spinner.hide();
      }
    }
  
  getPDF() {
    window.print();
  }

  onSearch(type: string, e: any) {
    this.dt1.filterGlobal(e, 'contains');
  }

  reset() {
    this.dt1.reset();
    this.filterVal = '';
  }
  containsNumber(value: string): boolean {
    return /\d/.test(value);
  }
  // loadPdfPages(): void {
  //   this.filteredControls.forEach((item) => {
  //     console.log(item.value);
  //     const loadingTask = pdfjsLib.getDocument(item.value);

  //     loadingTask.promise.then((pdf) => {
  //       pdf.getPage(1).then((page) => {
  //         const canvas = document.createElement('canvas');
  //         const context = canvas.getContext('2d');

  //         const viewport = page.getViewport({ scale: 1.0 });
  //         canvas.height = viewport.height;
  //         canvas.width = viewport.width;

  //         const renderContext = {
  //           canvasContext: context,
  //           viewport: viewport
  //         };

  //         page.render(renderContext).then(() => {
  //           const dataUrl = canvas.toDataURL('image/png');
  //           const safeDataUrl = this.sanitizer.bypassSecurityTrustResourceUrl(dataUrl);
  //           this.pdfPages.push(safeDataUrl);
  //         });
  //       });
  //     });
  //   });
  // }
  backBtn(){
    this.previewCheck = false;
    this.juryEvaluation = false
  }
  // getSafeUrl(url: string): SafeResourceUrl {
  //   return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  // }
}
