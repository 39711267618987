import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-analytic-report',
  templateUrl: './analytic-report.component.html',
  styleUrls: ['./analytic-report.component.scss']
})
export class AnalyticReportComponent implements OnInit {
  slug: any;
  getReportData: any = {};

  constructor(private apiService : ApiService, private activatedRoute : ActivatedRoute, private spinner : NgxSpinnerService) { }

  data : any = [
    {
      name : 'Top-line growth',
      marks : 135,
      rank : 1,
      total : 135
    },
    {
      name : 'Top-line growth',
      marks : 70,
      rank : 2,
      total : 75
    },
    {
      name : 'Top-line growth',
      marks : 30,
      rank : 3,
      total : 90
    }
  ]

  async ngOnInit() {

    this.spinner.show();

    this.slug = this.activatedRoute.snapshot.params;

    if(this.slug.slug) {
      await this.apiService.getResultJson(this.slug.slug).then((res:any) => {
        console.log(res);
        this.getReportData = res.data;
        this.data = JSON.parse(res?.data?.result);
        console.log(this.data);
      })
      this.data.forEach(element => {
        let rank = (element.rank / element.total_rank * 100);
        if(rank >= 90) {
          element.overallRank = 90;
        }
        else if(rank <= 10) {
          element.overallRank = 10;
        }
        else {
          element.overallRank = Number(rank.toFixed(2));
        }
      });
    }

    console.log(this.data);
    this.spinner.hide();
  }

  getPDF() {
    window.print();
  }

}
