<p-toast
  [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'flex-direction': 'column'}}">
</p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "red" type="square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner> -->

<!-- <ngx-spinner name="sp5" [fullScreen]="true" type="square-jelly-box" size="medium"></ngx-spinner> -->
<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- ********************** Main Content ******************** -->

<div class="main-content d-flex flex-column">

  <!-- <app-dashboard-navbar></app-dashboard-navbar> -->
  <app-navbar-style-one></app-navbar-style-one>

  <!-- ********************** Breadcrumb Area  ******************** -->

  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
    <h1>{{Content.title}}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <!-- <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li> -->
      <li class="item">{{Content.title}}</li>
    </ol>
  </div>

  <!-- ********************** End Breadcrumb Area  ******************** -->

  <!-- ********************** Add Master Form Area  ******************** -->

  <div class="add-listings-box">
    <form [formGroup]="awardCategoryForm" (ngSubmit)="onSubmit(form)" #form="ngForm">
      <div class="row mt-1">

        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
          <div class="form-group">Award Title <span style="color: red;">*</span>:

            <input formControlName="title" class="form-control" placeholder="Award"
              [class.is-invalid]="(form.submitted && awardCategoryForm.get('title')?.invalid) || awardCategoryForm.get('title')?.invalid && awardCategoryForm.get('title').touched" />
            <div class="invalid-feedback">Award title is required</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
          <div class="form-group">Turnover <span style="color: red;">*</span>:

            <select formControlName="turnover_id"
              [class.is-invalid]="(form.submitted && awardCategoryForm.get('turnover_id')?.invalid) || awardCategoryForm.get('turnover_id')?.invalid && awardCategoryForm.get('turnover_id').touched">
              <option [value]="null" disabled>Please select turnover</option>
              <option [ngValue]="item.id" *ngFor="let item of turnover">{{item.component}}
              </option>
            </select>
            <div class="invalid-feedback">Turnover is required</div>
          </div>
        </div>
        <!-- <div class="col-lg-1 col-md-4 col-sm-4 col-xs-12"></div> -->
        <div class="col-lg-3 col-md-2 col-sm-2 col-xs-12" style="text-align: right;">
          <div class="add-listings-btn">
            <button type="submit">Add</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>