<!-- <p-toast life="50000"></p-toast> -->
<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- ********************** Main Content ******************** -->

<div class="main-content d-flex flex-column">

  <!-- <app-dashboard-navbar></app-dashboard-navbar> -->
  <!-- <app-navbar-style-one></app-navbar-style-one> -->
  <router-outlet></router-outlet>
</div>