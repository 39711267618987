<!-- <ngx-spinner name="sp5" [fullScreen]="true" type="square-jelly-box" size="medium"></ngx-spinner> -->
<p-toast [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display:
  'flex', 'justify-content': 'center', 'align-items': 'center',
  'flex-direction': 'column'}}">
</p-toast>
<!-- <p-confirmDialog header="Confirmation" [style]="{width: '50vw'}" [baseZIndex]="10000"
  rejectButtonStyleClass="p-button-text"> -->
<!-- </p-confirmDialog> -->
<p-confirmDialog #cd [style]="{width: '30vw'}" [breakpoints]="{'960px':
  '92vw'}">
  <ng-template pTemplate="header">
    <h5>Confirmation</h5>
    <button pButton pRipple type="button" icon="pi pi-times" (click)="cd.close()"
      class="p-button-rounded p-button-secondary p-button-text"></button>
  </ng-template>
  <ng-template pTemplate="footer">
    <!-- <button type="button" pButton icon="pi pi-times" label="No" (click)="cd.reject()"></button> -->
    <!-- <p-button type="button" pButton icon="pi pi-check" styleClass="p-button-text" label="Yes"
      (click)="cd.accept()"></p-button> -->
    <p-button (click)="cd.accept()" icon="pi pi-check" label="Yes" styleClass="p-button-text"></p-button>
  </ng-template>
</p-confirmDialog>
<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- ********************** Main Content ******************** -->

<div class="main-content d-flex flex-column">

  <!-- <app-dashboard-navbar></app-dashboard-navbar> -->
  <app-navbar-style-one></app-navbar-style-one>

  <!-- ********************** Breadcrumb Area  ******************** -->

  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
    <h1>{{Content.title}}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <!-- <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li> -->
      <li class="item">{{Content.title}}</li>
    </ol>
  </div>

  <!-- ********************** End Breadcrumb Area  ******************** -->

  <!-- ********************** Add Master Form Area  ******************** -->
  <div class="Master-view-box">
    <div class="theader">
      <!-- <ng-template pTemplate="caption"> -->
        
      <div class="d-flex search-bar">
        <span class="p-input-icon-left ml-auto mr-2">
          <i class="pi pi-search"></i>
          <input pInputText type="text" name="filterValue" (input)="onSearch('dt1', $event.target.value)" placeholder="Search keyword"
            [(ngModel)]="filterVal" />
        </span>
        <p-button type="button" class="ml-2" (click)="reset()" icon="bx
          bx-reset"></p-button>
      </div>
      <!-- <div>
        <p-multiSelect (onChange)="onMultiSelect('dt2', $event.value)"
        [options]="statusList" [(ngModel)]="multiSelectFilterVal" defaultLabel="Select a Status"
        optionLabel="label" optionValue="value" placeholder="Select Status"
        selectedItemsLabel="{0} items selected"></p-multiSelect>
      </div> -->
      <ng-container *ngIf="localStorage.role == 'Admin'">
        <div>
          <button class="p-button" (click)="sendShortListedId()">Site Visit</button>
          <button class="ml-2 p-button" (click)="sendEmail()">Send Report</button>
        </div>
      </ng-container>
      <!-- <div class="d-flex btn-box" *ngIf="localStorage?.role=='Admin'">
        <div class="d-flex">
          <div class="add-listings-btn mr-2">
            <button type="button" (click)="bulkApproval()"> Approve</button>
          </div>
          <div class="add-listings-btn">
            <button type="button" (click)="bulkReject()"> Reject</button>
          </div>
        </div>
        <div class="add-listings-btn ml-2">
          <button type="button" (click)="sendEmail('bulk', 'bulk')"> Send Email</button>
        </div>
      </div> -->
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12">
        <!-- *********************************************for user role table************************************************ -->
        <ng-container *ngIf="localStorage.role == 'User'">
          <p-table #dt1 #dt2 [value]="users" sortField="company_name" rowGroupMode="subheader" sortMode="single"
            groupRowsBy="company_name" [paginator]="true" dataKey="company_name" [rows]="10"
            [showCurrentPageReport]="true" [rowsPerPageOptions]="[10,25,50]" responsiveLayout="scroll"
            [loading]="loading" currentPageReportTemplate="Showing {first} to {last} of
            {totalRecords} entries" [globalFilterFields]="['name', 'status',
            'company_name',
            'award.award_name', 'category']">
            <ng-template pTemplate="header" style="min-height:
              500px;">
              <tr>
                <th style="min-width: 100px; text-align: center; white-space:
                  no-wrap;">Company Name</th>
                <th style="max-width: 170px; width: 170px; text-align: center;
                  white-space: no-wrap;">
                  Action
                </th>
                <th style="max-width: 150px; width: 150px; white-space:
                  no-wrap;">Status</th>
                <th style="min-width: 150px; white-space: no-wrap;">Award Name</th>
                <th style="min-width: 150px; white-space: no-wrap;">Award Category</th>
                <!-- <th style="min-width: 150px; white-space: no-wrap;">Contact
                  Person</th> -->
                <!-- <th style="min-width: 200px; white-space: no-wrap;">Organization
                  Name</th> -->
              </tr>
            </ng-template>

            <ng-template pTemplate="body" let-item let-rowIndex="rowIndex" let-expanded="expanded">
              <tr>
                <td>
                    <span class="p-text-bold">{{item.company_name}}</span>
                </td>
                <td style="text-align: center;">
                  <button pButton pRipple icon="bx bx-pencil" [disabled]="item.status == 'Completed'"
                    class="p-button-rounded p-button-text p-button-primary mr-2"
                    (click)="editForm(item.slug, item.status, item.company_name, item.registration_id)"></button>
                  <!-- <button pButton pRipple icon="bx bx-pencil"
                    class="p-button-rounded p-button-text p-button-primary mr-2"
                    (click)="editForm(item.slug, item.status, item.company_name, item.registration_id)"></button> -->
                </td>
                <td class="stage-status">
                  <span class="pending" *ngIf="item.status == ''">
                    Pending
                  </span>
                  <span class="in-progress" *ngIf="item.status == 'In Progress'">
                    {{item.status}}
                  </span>
                  <span class="approved" *ngIf="item.status == 'Completed'">
                    {{item.status}}
                  </span>
                </td>
                <td>{{item.award_name}}</td>
                <td>{{item.award_category}}</td>
                <!-- <td>{{item.name}}</td> -->
                <!-- <td>{{item.company_name}}</td> -->
              </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
              <tr>
                <td colspan="5">No data found.</td>
              </tr>
            </ng-template>
          </p-table>
        </ng-container>

        <ng-container *ngIf="localStorage.role == 'Admin'">
          
          <p-table #dt1 #dt2 [value]="users" sortField="award_category" sortMode="single" dataKey="award_category"
            rowGroupMode="subheader" groupRowsBy="award_category" [rows]="10" [showCurrentPageReport]="true" styleClass="p-datatable-sm
            p-datatable-customers" editMode="row" responsiveLayout="scroll" [globalFilterFields]="['company_name']">
            <ng-template pTemplate="header">
              <tr>
                <th class="text-center">
                  <input class="form-check-input" type="checkbox" id="checkAll" value="all" [(ngModel)]="checkAll"
                      (change)="onAllSelect($event)">
                </th>
                <th style="width:20%">S No.</th>
                <th style="width:20%">Company Name</th>
                <th style="width:20%">Award Name</th>
                <th style="width:20%">Status</th>
                <th style="width:20%" *ngIf="localStorage.role == 'Admin'">Analytic Report</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="groupheader" let-customer let-rowIndex="rowIndex" let-expanded="expanded">
              <tr>
                <td colspan="6">
                  <button type="button" pButton pRipple [pRowToggler]="customer"
                    class="p-button-text p-button-rounded p-button-plain mr-2"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
                  <span class="font-bold ml-2" style="padding: 8px;display:inline-block;">{{customer?.award_category}}</span>
                </td>
              </tr>
            </ng-template>
            <!-- <ng-template pTemplate="groupfooter" let-customer>
              <tr class="p-rowgroup-footer">
                <td colspan="4" style="text-align: right">Total Customers</td>
                <td>{{calculateCustomerTotal(customer.representative.name)}}</td>
              </tr>
            </ng-template> -->
            <ng-template pTemplate="rowexpansion" let-customer let-i="rowIndex">
              <tr>
                <td class="text-center">
                  <input *ngIf="customer.status == 'Completed'" class="form-check-input" type="checkbox" [id]="i" value="{{customer.id}}"
                      (change)="onChange($event, customer)">
                </td>
                <td>
                  {{i+1}}
                </td>
                <td>
                  {{customer?.company_name}}
                </td>
                <td>
                  {{customer?.award_name}}
                </td>
                <td class="status">
                  <span class="pending" *ngIf="customer.status == ''">
                    Pending
                  </span>
                  <span class="pending" *ngIf="customer.status == null">
                    Pending
                  </span>
                  <span class="in-progress" *ngIf="customer.status == 'In Progress'">
                    In Progress
                  </span>
                  <span class="approved" *ngIf="customer.status == 'Completed'">
                    Completed
                  </span>
                </td>
                <td *ngIf="localStorage.role == 'Admin'">
                  <button *ngIf="customer.status == 'Completed'" routerLink="/analytic-report/{{customer?.registration_id}}" class="p-button">Report</button>
                </td>
              </tr>
            </ng-template>
          </p-table>

        </ng-container>

      </div>
    </div>
    <!-- <ng-container *ngFor="let item of uploadData;index as index">
      <input type="text" name="id" [(ngModel)]="item.results_ids">
      <input type="file" name="file" (change)="file($event, index)">
      <br>
    </ng-container>
    <button (click)="addMore()">add</button>
    <button (click)="submit()">submit</button> -->
  </div>
  <div class="flex-grow-1"></div>

  <app-copyrights></app-copyrights>
</div>