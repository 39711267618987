<p-toast
    [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'flex-direction': 'column'}}">
</p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>


<!-- <ng-container *ngIf="!previewCheck">
    <app-dashboard-sidemenu></app-dashboard-sidemenu>
</ng-container> -->

<!-- ********************** Main Content ******************** -->

<div class="main-content d-flex flex-column" style="padding-left: 30px;" *ngIf="previewCheck == false">

    <!-- <app-dashboard-navbar></app-dashboard-navbar> -->
    <!-- <ng-container *ngIf="!previewCheck"> -->
    <app-navbar-style-one></app-navbar-style-one>
    <!-- </ng-container> -->

    <!-- ********************** Breadcrumb Area  ******************** -->

    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <p-button type="button" #back_btn class="test mr-2" (click)="onBackPress()" icon="bx bx-arrow-back" label=""
            iconPos="left" pTooltip="Back" tooltipPosition="bottom"></p-button>
        <h1>{{Content.title}}</h1>
        <ol class="breadcrumb">
            <li class="item"><a routerLink="/">Home</a></li>
            <!-- <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li> -->
            <li class="item">{{Content.title}}</li>
        </ol>
    </div>
    <div class="add-listings-box" *ngIf="sections.length > 0">
        <div class="heading">
            <img src="assets/img/ucci-awards-logo.png" alt="">
            <h2>{{title}} {{nextYear}} / {{hindiTitle}} {{nextYear}}</h2>
            <p> Please complete the form below and provide details of your business and company.
                कृपया निम्नलिखित फार्म को पूरा भरें तथा अपने व्यवसाय एवं कम्पनी के विषय में जानकारी देवे:</p>
        </div>

        <ng-container *ngIf="!previewCheck">
            <lib-renderer [sections]="sections" (sectionsData)="getFormResponse($event)"></lib-renderer>

            <!-- <button></button> -->
        </ng-container>
        <div class="row">
            <div class="col-12">
                <label class="my-2 fw-bold">
                    In case of any clarifications call, +91 6377205389 / किसी भी स्पष्टीकरण के मामले में, +91 6377205389
                    पर कॉल करें
                </label>
            </div>
        </div>
    </div>
    <!-- <button (click)="getExcelFromJson()">get excel</button> -->
    <!-- <lib-form-builder [buttonFields]="buttonFields" (formJson)="getJson($event)"></lib-form-builder> -->
    <!-- <lib-form-renderer *ngIf="jsonData" [jsonFormData]="jsonData"></lib-form-renderer> -->
    <!-- ********************** End Breadcrumb Area  ******************** -->

</div>


<ng-container *ngIf="previewCheck">
    <div class="add-listings-box" *ngIf="sections.length > 0">
        <div class="heading">
            <img src="assets/img/ucci-awards-logo.png" alt="">
            <h2>{{title}} {{nextYear}} / {{hindiTitle}} {{nextYear}}</h2>
            <!-- <p> Please complete the form below and provide details of your business and company.
                कृपया निम्नलिखित फार्म को पूरा भरें तथा अपने व्यवसाय एवं कम्पनी के विषय में जानकारी देवे:</p> -->
        </div>

        <div class="d-flex justify-content-between align-items-center position-sticky button_div"
            style="top: 0;background:white;padding:20px 0;">
            <p-button type="button" #back_btn class="test " (click)="previewCheck = false" icon="bx bx-arrow-back"
                label="" iconPos="left">
            </p-button>
            <button (click)="getPDF()" class="pdf_btn">Save as PDF</button>
        </div>
        <br>
        <div class="preview">
            <ng-container *ngFor="let section of sections">
                <ng-container *ngFor="let control of section.controls; let i = index">
                    <div *ngIf="i === 0" class="section-heading">
                        <span>{{control.label}}</span>
                    </div>
                    <div class="group">
                        <label for="" *ngIf="i !== 0" class="label">{{control.label}}</label>
                        <ng-container *ngIf="control.name !='table'">
                            <div class="value" *ngIf="(control.name !== 'label' && control.name !== 'file')">
                                <!-- <i class="bx bx-circle" style="font-size: 8px;
                                color: black;
                                background: black;
                                border-radius: 25px;"></i> -->
                                <i class="fa fa-thin fa-arrow-right mt-1"></i>
                                <span>{{control.value != '' ? control.value : '-'}}</span>
                            </div>
                            <!-- <div class="value" *ngIf="control.id === 'date'">
                              
                                <i class="fa fa-thin fa-arrow-right mt-1"></i>
                                <span>{{extractedDate}}</span>
                            </div> -->
                            <div class="value color" *ngIf="control.name === 'file'">
                                <ng-container *ngIf="control.value != '' ">
                                    <i class="fa fa-thin fa-arrow-right mt-1"></i>
                                    <!-- <span><a href="{{control.value}}" target="_blank">{{control.value}}</a></span> -->
                                    <span>Uploaded</span>
                                </ng-container>
                                <ng-container *ngIf="control.value == '' ">
                                    <i class="fa fa-thin fa-arrow-right mt-1"></i>
                                    <span>-</span>
                                </ng-container>
                            </div>
                        </ng-container>
                    </div>
                    <ng-contaier *ngIf="control.name =='table'">
                        <table>
                            <thead>
                                <tr>
                                    <ng-container *ngFor="let colHead of control.columns">
                                        <th class="table-label">
                                            {{colHead.label}}
                                        </th>
                                    </ng-container>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let tableRow of control.rows">
                                    <tr>
                                        <ng-container *ngFor="let tableCol of tableRow">
                                            <!-- {{tableCol | json}} -->
                                            <td *ngIf="(tableCol.name !== 'label' && tableCol.name !== 'file')">
                                                <span class="value" *ngIf="tableCol.value != ''">{{
                                                    tableCol.value }}</span>
                                                <span class="value" *ngIf="tableCol.value == ''"
                                                    style="display: flex; justify-content: center; align-items: center;">-</span>

                                            </td>

                                            <td *ngIf="(tableCol.name == 'label')" style="background-color: #f9f9f9;">
                                                <label for="" class="table-label">{{tableCol.label}}</label>
                                            </td>
                                            <td *ngIf="tableCol.name == 'file'" style="background-color: #f9f9f9;">
                                                <ng-container *ngIf="tableCol.value !== ''">
                                                    <label for="" class="value"
                                                        style="justify-content: center;">Uploaded</label>
                                                    <!-- <label for="" class="value color"><a href="{{tableCol.value}}"
                                                            target="_blank">{{tableCol.value}}</a></label> -->
                                                </ng-container>
                                                <ng-container *ngIf="tableCol.value === ''">
                                                    <label for="" class="value"
                                                        style="justify-content: center;">-</label>
                                                </ng-container>
                                            </td>
                                        </ng-container>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </ng-contaier>
                    <!-- <br> -->
                </ng-container>
            </ng-container>
        </div>
    </div>
</ng-container>