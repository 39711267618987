<app-spinner></app-spinner>
<div class="report_btn" style="padding:15px;position : sticky;top:0;text-align: right;background-color: whitesmoke;z-index: 99;">
    <button (click)="getPDF()" class="p-button report_btn">Save as PDF</button>
</div>
<div class="pdf_section" *ngIf="data?.length > 0">
    <div class="logo_section">
        <img src="assets/img/ucci-awards-logo.png" alt="">
        <h4>Udaipur Chamber of Commerce & Industry</h4>
        <h4>Award Name : {{getReportData?.award_registration?.award?.award_name}}</h4>
        <h4>Company Name : {{getReportData?.award_registration?.company_name}}</h4>
        <h4>Confidential</h4>
    </div>

    <!-- MANUFACTURING -->

    <div class="parametersSection manufacturingSection" >
        <h4 style="padding : 0 15px;margin-top: 30px;">Key Excellence Parameters (2024)</h4>
        <div class="headingSection">
            <h2 class="heading">Financial</h2>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[0]?.name}}</h5>
                <span>Sustained growth over multiple years indicates good product-market fit, and strategy.</span><br>
                <!-- <span>( Position in Peer Group )</span> -->
                <div class="mergeDiv">
                    <p class="col-auto">Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'right': data[0]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[1]?.name}}</h5>
                <span>Profitability maintenance indicates excellence in operations.</span><br>
                <h6> <!-- <span>( Position in Peer Group )</span> --></h6>
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'right': data[1]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[2]?.name}}</h5>
                
                <span>Efficient use of capital is an indicator of excellence in business decision making.</span><br>
                <h6> <!-- <span>( Position in Peer Group )</span> --></h6>
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'right': data[2]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[3]?.name}}</h5>
                <span>Quality of governance as indicated by decision making structures and involvement of diverse individuals.</span><br>
                <h6> <!-- <span>( Position in Peer Group )</span> --></h6>
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'right': data[3]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection ebita" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[4]?.name}}</h5>
                <span>Organization openness and shared vision are critical for sustained growth and success.</span><br>
                <h6> <!-- <span>( Position in Peer Group )</span> --></h6>
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'right': data[4]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="headingSection manEBITA">
            <h2 class="heading">People & Management</h2>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[5]?.name}}</h5>
                <span>Independently assessed credit worthiness, indicates business sustainability and ability to fulfill financial obligations.</span><br>
                <h6> </h6>
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'right': data[5]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[6]?.name}}</h5>
                <span>Compliance to best practices and standards leads to competitive advantage and customer satisfaction.</span><br>
                <h6> <!-- <span>( Position in Peer Group )</span> --></h6>
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'right': data[6]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[7]?.name}}</h5>
                <span>A positive employee growth rate indicates that the company is expanding its workforce, reflecting effective talent 
                    acquisition and organizational growth.</span><br>
                <h6> <!-- <span>( Position in Peer Group )</span> --></h6>
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'right': data[7]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[8]?.name}}</h5>
                <span>A diverse workforce, with employees from various backgrounds and perspectives, fosters creativity, innovation, and 
                    better product development.</span><br>
                <h6> <!-- <span>( Position in Peer Group )</span> --></h6>
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'right': data[8]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[9]?.name}}</h5>
                <span>Investment in training and development is important for future growth and competitiveness.</span><br>
                <h6> <!-- <span>( Position in Peer Group )</span> --></h6>
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'right': data[9]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection proEducation" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[10]?.name}}</h5>
                <span>Conscious deliberation and action to improve products and/or processes is critical for company's long term success.</span><br>
                <h6> <!-- <span>( Position in Peer Group )</span> --></h6>
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'right': data[10]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <!-- <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[14]?.name}}</h5>
                <span>Acting as responsible members of the community is a critical aspect of business excellence.</span><br>
              
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'right': data[14]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div> -->
        <div class="headingSection">
            <h2 class="heading">Innovation</h2>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[11]?.name}}</h5>
                <span>Being open to new technologies for production or operations is necessary for an organization’s success and survival.</span><br>
                <h6> <!-- <span>( Position in Peer Group )</span> --></h6>
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'right': data[11]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[12]?.name}}</h5>
                <span>Strong information technology investment is necessary for organizational sustenance and growth.</span><br>
                <h6> <!-- <span>( Position in Peer Group )</span> --></h6>
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'right': data[12]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[13]?.name}}</h5>
                <span>Acting as responsible members of the community is a critical aspect of business excellence.</span><br>
                <h6> <!-- <span>( Position in Peer Group )</span> --></h6>
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'right': data[13]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>

        <!-- <div class="headingSection manSocial">
            <h2 class="heading">Social Responsibilities</h2>
        </div> -->
    </div>

    <!-- SERVICES -->

    <div class="parametersSection serviceSection" *ngIf="getReportData?.category == 'Services'">
        <h4 style="padding : 0 15px;margin-top: 30px;">Key Excellence Parameters (2023)</h4>
        <div class="headingSection">
            <h2 class="heading">Financial</h2>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[2]?.name}}</h5>
                <span>Sustained growth over multiple years indicates good product-market fit, and strategy.</span><br>
                <!-- <span>( Position in Peer Group )</span> -->
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'left': data[2]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[3]?.name}}</h5>
                <span>Profitability maintenance indicates excellence in operations.</span><br>
                <!-- <span>( Position in Peer Group )</span> -->
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'left': data[3]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[4]?.name}}</h5>
                <span>Value addition per employee is a good way to assess if the people potential is fully used.</span><br>
                <!-- <span>( Position in Peer Group )</span> -->
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'left': data[4]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="headingSection">
            <h2 class="heading">People & Management</h2>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[0]?.name}}</h5>
                <span>Indicates overall organizational potential, as quality of business decisions often depends on professional education and experience of the decision makers</span><br>
                <!-- <span>( Position in Peer Group )</span> -->
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'left': data[0]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection serPandM" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[1]?.name}}</h5>
                <span>Organization openness and shared vision are critical sustained growth and success.</span><br>
                <!-- <span>( Position in Peer Group )</span> -->
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'left': data[1]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[5]?.name}}</h5>
                <span>Companies which ensure employee wellbeing and growth are better able to handle market challenges.</span><br>
                <!-- <span>( Position in Peer Group )</span> -->
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'left': data[5]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[6]?.name | titlecase}}</h5>
                <span>Compliance to best practices and standards leads to competitive advantage and customer satisfaction.</span><br>
                <!-- <span>( Position in Peer Group )</span> -->
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'left': data[6]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[7]?.name}}</h5>
                <span>Qualified and/or professional workforce ensure excellence in operations and sustained innovation.</span><br>
                <!-- <span>( Position in Peer Group )</span> -->
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'left': data[7]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[8]?.name}}</h5>
                <span>A more competitive workforce, increased employee retention, and higher employee engagement.</span><br>
                <!-- <span>( Position in Peer Group )</span> -->
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'left': data[8]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[12]?.name}}</h5>
                <span>Acting as responsible members of the community is a critical aspect of business excellence.</span><br>
                <!-- <span>( Position in Peer Group )</span> -->
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'left': data[12]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="headingSection serPandMsix">
            <h2 class="heading">Innovation</h2>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[9]?.name}}</h5>
                <span>Conscious deliberation and action to improve products and/or processes is critical for company's long term success.</span><br>
                <!-- <span>( Position in Peer Group )</span> -->
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'left': data[9]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[10]?.name}}</h5>
                <span>Being open to new technologies is necessary for an organization’s success and survival.</span><br>
                <!-- <span>( Position in Peer Group )</span> -->
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'left': data[10]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[11]?.name}}</h5>
                <span>Improve the company processes, bring new and improved products and services to market, increase its efficiency and, most importantly, improve its profitability.</span><br>
                <!-- <span>( Position in Peer Group )</span> -->
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'left': data[11]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <div class="subheadingSection" style="padding-left: 10px;">
            <div class="widthDiv">
                <h5>{{data[13]?.name}}</h5>
                <span>Digital engagement is critical for market discovery and product placement.</span><br>
                <!-- <span>( Position in Peer Group )</span> -->
                <div class="mergeDiv">
                    <p>Need Improvement</p>
                    <div class="progressBar">
                        <angular-gradient-progressbar [type]="2"></angular-gradient-progressbar>
                        <div class="triangle-top" [ngStyle]="{'left': data[13]?.overallRank+'%'}">
                            <div class="position">
                                Position in Peer Group
                            </div>
                        </div>
                    </div>
                    <p>Excellent</p>
                </div>
            </div>
        </div>
        <!-- <div class="headingSection serviceSocial">
            <h2 class="heading">Social Responsibilities</h2>
        </div> -->
    </div>

    <div class="mcqSection">
        <p class="question m-0">Business Vision:</p>
        <p class="answer m-0">
            https://ctb.ku.edu/en/table-of-contents/structure/strategic-planning/vision-mission-statements/main</p>
        <p class="answer m-0">
            https://www.mckinsey.com/business-functions/organization/our-insights/getting-personal-about-change</p>
        <p class="answer m-0">https://iveybusinessjournal.com/being-an-old-organization-can-still-be-a-good-thing/</p>
        <p class="answer m-0">https://www.entrepreneur.com/article/290803</p>
        <p class="question m-0">The best-kept secret of business growth:</p>
        <p class="answer m-0">
            https://www.forbes.com/sites/forbesagencycouncil/2017/11/20/the-best-kept-secret-of-business-growth/#6ec86e061977
        </p>
        <p class="question m-0">Unleashing long-term value through operations excellence:</p>
        <p class="answer m-0">
            https://www.mckinsey.com/business-functions/operations/our-insights/unleashing-long-term-value-through-operations-excellence
        </p>
        <p class="answer m-0">
            https://www.investopedia.com/ask/answers/042415/what-are-some-ways-company-can-improve-its-return-capital-employed-roce.asp
        </p>
        <p class="question m-0">How can a company increase its return on total assets?</p>
        <p class="answer m-0">
            https://www.investopedia.com/ask/answers/040115/how-can-company-increase-its-return-total-assets.asp</p>
        <p class="question m-0">Who really makes the big decisions in your company?</p>
        <p class="answer m-0">https://hbr.org/2011/12/who-really-makes-the-big-decisions-in-your-company</p>
        <p class="question m-0">Are your certifications helping you win business?</p>
        <p class="answer m-0">https://blog.thomasnet.com/manufacturing-quality-certifications</p>
        <p class="question m-0">Choosing social causes that make sense for your company:</p>
        <p class="answer m-0">
            https://www.forbes.com/sites/causeintegration/2017/01/16/choosing-social-causes-that-make-sense-for-your-company/#6fe5cd146df9
        </p>
        <p class="question m-0">People measures::</p>
        <p class="answer m-0">https://hbr.org/2018/11/better-people-analytics</p>
        <p class="answer m-0">
            https://www.cipd.co.uk/Images/People-Measurement-and-Reporting-From-Theory-to-Practice_tcm18-15940.pdf</p>
        <p class="answer m-0">https://www.inc.com/guides/2010/08/how-to-implement-a-continuing-education-program.html
        </p>
        <p class="question m-0">When companies should invest in training their employees - and when they shouldn’t</p>
        <p class="answer m-0">
            https://hbr.org/2018/10/when-companies-should-invest-in-training-their-employees-and-when-they-shouldn’t:
        </p>
        <p class="question m-0">The new metrics of corporate performance: Profit per employee</p>
        <p class="answer m-0">
            https://www.mckinsey.com/business-functions/strategy-and-corporate-finance/our-insights/the-new-metrics-of-corporate-performance-profit-per-employee
        </p>
        <p class="question m-0">Innovation:</p>
        <p class="answer m-0">https://hbr.org/2019/07/why-employees-dont-share-knowledge-with-each-other</p>
        <p class="answer m-0">https://www.gsb.stanford.edu/insights/how-stay-ahead-when-rules-change</p>
        <p class="question m-0">Rethinking innovation in industrial manufacturing:</p>
        <p class="answer m-0">
            https://www.pwc.com/gx/en/industrial-manufacturing/publications/pdf/pwc-rethinking-innovation-in-industrial-manufacturing-are-you-up-for-the-challenge.pdf
        </p>
        <p class="answer m-0">https://bia.ca/how-to-re-shape-your-culture-to-be-more-innovative/</p>
        <p class="question m-0">Here're the technological innovations that manufacturing industry should adopt:</p>
        <p class="answer m-0">https://www.entrepreneur.com/article/332720</p>
        <p class="question m-0">Disclaimer:</p>
        <div class="answer m-0">
            <ul>
                <li>This report is auto generated using the data provided by you in your award application form.</li>
                <li>The inferences in the report are indicative of the fact that by applying for the awards, you allowed
                    your company to be benchmarked as per the
                    award’scriteria.</li>
                <li>This report and all the insights provided herein are not advisory in nature.</li>
                <li>Udaipur Chamber of Commerce & Industry and its jury are not liable responsible for any outcomes as a
                    result of any business decision taken by your
                    organisation based on this report.</li>
                <li>The ratings / inferences presented in the report only suggest your standing within your award
                    application category which is subjective in nature.</li>
                <li>Further analysis is highly recommended and UCCI can help you get in touch with professional
                    consultants to improve your business indicators.</li>
            </ul>
        </div>
    </div>
</div>