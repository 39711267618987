<!-- Start Copyright End -->
<div class="copyrights-area">
    <div class="row align-items-center">
        <div class="col-lg-6 col-sm-6 col-md-6">
            <p>© 2024 Udaipur Chamber of Commerce & Industry | All Rights Reserved</p>
        </div>
        <div class="col-lg-6 col-sm-6 col-md-6 text-end">
            <p>Designed by <a href="https://webanixsolutions.com/" target="_blank">Webanix Pvt Ltd</a></p>
        </div>


    </div>
</div>
<!-- End Copyright End -->