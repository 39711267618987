import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Table } from 'primeng/table';
import { ObjectUnsubscribedError } from 'rxjs';
import { CommonClass } from 'src/app/common';
import { ApiService } from 'src/app/services/api.service';
import { FormBuilderService } from 'src/app/services/form-builder.service';

@Component({
  selector: 'app-registered-users',
  templateUrl: './registered-users.component.html',
  styleUrls: ['./registered-users.component.scss'],
  providers: [MessageService, ConfirmationService],
})
export class RegisteredUsersComponent implements OnInit {
  @ViewChild('dt1') dt1: Table;
  @ViewChild('dt2') dt2: Table;

  breadcrumb: any[];

  filterVal: string;

  previewCheck: boolean = false;

  loading: boolean = false;

  users: any = [];

  displayModal: boolean = false;

  displayMaximizable: boolean = false;


  details: any;

  statusModal: boolean = false;

  id: number;

  award_category:string = '';

  company_name :string = '';

  sections: any = [];

  preYear: any;

  currentYear: any;

  date: Date = new Date();
  currncyLength = 0;

  statusForm = this.fb.group({
    status: ['', Validators.required],
    remark: ['', Validators.required],
  });

  status: string;
  localStorage: any;
  selectedUser: any[] = [];
  turnoverAmount: string;
  formResponse: any = {};
  title: any;
  hindiTitle: string;
  nextYear: number;
  displayForm:boolean = false;

  signUpSubmitted = false;
  submitted = false;
  resSignupMsg: string = '';
  resSignupMsgCheck: string = ' ';
  signupState: boolean = false;
  gstType: any;
  spinnerMsg: string;

  displayMaximizableEdit: boolean = false;
  editingUser: any = null;

  registerForm = this.fb.group({
    name: ['', [Validators.required]],
    mobile_no: ['', [Validators.required, Validators.pattern('^[0-9]{10}$')]],
    email: ['', [Validators.required, Validators.email]],
    company_name: ['', Validators.required],
    company_GST: [''],
    company_PAN: ['', Validators.required],
    company_state: ['', Validators.required],
    company_city: ['', Validators.required],
    company_pincode: [
      '',
      [Validators.required, Validators.pattern('^[0-9]{6}$')],
    ],
    company_address: ['', Validators.required],
    address_line2: [''],
    award_type: [null, Validators.required],
    gst_type: ['', Validators.required],
    classification_id: [null, Validators.required],
    turnover: [null, [Validators.required, Validators.min(100000)]],
    category: [''],
    achievements: ['', [Validators.required]],
    reason: ['', [Validators.required]],
    remark: ['', [Validators.required]],
  });
  state: any;
  classification: any = [];
  commonClasifications: any;
  // emailBoolean: boolean = false;

  constructor(
    private apiService: ApiService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
    private commonFunction: CommonClass,
    private confirmationService: ConfirmationService,
    private _formBuilder: FormBuilderService
  ) { }

  async ngOnInit(): Promise<void> {
    this.localStorage = this.commonFunction.getLocalStorage();
    this.spinner.show();
    if (this.localStorage.role != 'Auditor') {
      this.getUsers();
      this.breadcrumb = [
        {
          title:
            this.localStorage.role == 'User'
              ? 'My Registrations'
              : 'Registered Users',
          subTitle: 'Home',
        },
      ];
    }
    else {
      // await this._formBuilder.getAPI('response/all').then((res:any)=> {
      //   this.spinner.hide();
      //   console.log(res);
      //   // this.users.push(...res?.data['CSR']);
      //   // this.users.push(...res?.data['Manufacturing']);
      //   // this.users.push(...res?.data['Services']);
      //   // this.users.push(...res?.data['Social Enterprise']);
      // })
      console.log(this.users);

      await this._formBuilder.getAPI('response/allData').then((res: any) => {
        this.spinner.hide();
        console.log(res);
        this.users = res?.data?.filter((res:any)=> res?.status == 'Completed');
      })
    }
    this.currentYear = this.date.getFullYear();
    this.preYear = this.currentYear - 1;

    // this.initializeForm();
    this.getClassification();
    this.getState();
  }

  // get All registered users

  getUsers() {
    this.apiService.getRegisteredUsers().subscribe((res: any) => {
      console.log(res);
      let users: any = res?.data;
      this.users = users.reverse();
      this.spinner.hide();
      // this.displayModal = true
    });
  }

  // search function

  onSearch(type: string, e: any) {
    // if(type == "dt1") {
    this.dt1.filterGlobal(e, 'contains');
    // }else if(type == "dt1") {
    //   this.dt1.filterGlobal(e, 'contains')
    // }
  }

  // Clear search filter
  reset() {
    this.dt1.reset();
    this.filterVal = '';
  }

  // On Approve presss

  onAprrovPress(item: any, status: string) {
    if (item.status === 'Pending') {
      this.id = item.id;
      this.status = status;
      // this.statusModal = true;
      let approvedId = [];
      this.confirmationService.confirm({
        message: 'Are you sure you want to approve this application?',
        accept: () => {
          this.spinner.show();
          let id = this.id;
          approvedId.push({ id });

          console.log(approvedId);

          let obj: any = {
            // queue_approval: true,
            award_registrations: approvedId,
            status: 'Approved',
          };
          console.log(obj);

          this.apiService.updateStatus(obj).subscribe((res: any) => {
            console.log(res);
            this.selectedUser = [];
            this.spinner.hide();
            this.messageService.add({
              severity: 'success',
              detail: res.message,
            });
            this.getUsers();
          });
        },
      });
    }

    if (status === 'approve') {
      this.statusForm.controls['remark'].clearValidators();
      this.statusForm.controls['remark'].updateValueAndValidity();
    }
  }

  statusUpdate(form) {
    if (this.status === 'approve') {
      this.statusForm.patchValue({
        status: 'Approved',
      });
    } else if (this.status === 'reject') {
      this.statusForm.patchValue({
        status: 'Rejected',
      });
    }
    // debugger;
    console.log(this.statusForm.valid);

    if (this.statusForm.valid) {
      let rejectObj = {
        award_registrations: [{ id: this.id }],
        status: this.statusForm.value.status,
        remark: this.statusForm.value.remark,
      };
      this.spinner.show();
      this.apiService.updateStatus(rejectObj).subscribe((res: any) => {
        console.log(res);
        this.statusModal = false;
        form.resetForm();
        this.getUsers();
        this.spinner.hide();
        this.messageService.add({
          severity: 'success',
          detail: res.message,
        });
      });
    } else if (this.statusForm.invalid) {
      this.messageService.add({
        severity: 'error',
        detail: 'Please fill all details',
      });
    }
  }

  // On Reject Press

  onRejectPress(item: any, status: string) {
    if (item.status === 'Pending') {
      this.id = item.id;
      this.status = status;
      this.statusModal = true;
    }
    if (status === 'reject') {
      this.statusForm.controls['remark'].setValidators(Validators.required);
      this.statusForm.controls['remark'].updateValueAndValidity();
    }
    // this.apiService.updateStatus(item.id, obj).subscribe((res: any) => {
    //   console.log(res);
    // });
  }

  onViewPress(item: any) {
    console.log(item);
    let selectedUser = [];
    selectedUser.push(item);
    this.details = selectedUser;

    this.displayMaximizable = true;

    console.log(this.details);
  }
 // edit on view dailog
 onEditPress(item: any) {
  console.log(item);
  let selectedUser = [];
  selectedUser.push(item);
  this.details = selectedUser;

  // this is for edit
  this.editingUser = item;
  this.displayMaximizableEdit = true;
  this.populateEditForm(item);

  console.log(this.details);
}
  // Email sending function

  onMailSentPress(item: any) {
    console.clear();
    console.log(item);
  }

  // Approve in bulk

  bulkApproval() {
    // debugger;
    if (this.selectedUser.length !== 0) {
      // let approvalObj = [
      //   award_registrations: [{
      //     id: 1
      //   }]
      // ]
      let approvedId = [];
      this.selectedUser.forEach((element) => {
        if (element.status !== 'Approved' && element.status !== 'Rejected') {
          console.log(element);
          var id = element.id;
          approvedId.push({ id });
        }
      });
      this.confirmationService.confirm({
        message: 'Are you sure you want to approve selected users?',
        accept: () => {
          this.spinner.show();

          console.log(approvedId);

          let obj: any = {
            // queue_approval: true,
            award_registrations: approvedId,
            status: 'Approved',
          };
          console.log(obj);

          this.apiService.updateStatus(obj).subscribe((res: any) => {
            console.log(res);
            this.selectedUser = [];
            this.getUsers();
            this.spinner.hide();
            this.messageService.add({
              severity: 'success',
              detail: res.message,
            });
          });
        },
        // reject: ()
      });
    } else if (this.selectedUser.length === 0) {
      this.messageService.add({
        severity: 'error',
        // summary: 'Error',
        detail: 'Please select at least one field',
      });
    }
    // this.apiService.postBulkApproval()
  }

  // Bulk reject
  bulkReject() {
    // debugger;
    if (this.selectedUser.length !== 0) {
      // let approvalObj = [
      //   award_registrations: [{
      //     id: 1
      //   }]
      // ]
      let approvedId = [];
      this.confirmationService.confirm({
        message: 'Are you sure you want to reject selected users?',
        accept: () => {
          this.spinner.show();
          this.selectedUser.forEach((element) => {
            if (
              element.status !== 'Approved' &&
              element.status !== 'Rejected'
            ) {
              var id = element.id;
              approvedId.push({ id });
            }
          });

          console.log(approvedId);

          let obj: any = {
            // queue_approval: true,
            award_registrations: approvedId,
            status: 'Rejected',
          };
          console.log(obj);

          this.apiService.updateStatus(obj).subscribe((res: any) => {
            console.log(res);
            this.selectedUser = [];
            this.spinner.hide();
            this.messageService.add({
              severity: 'success',
              detail: res.message,
            });
            this.getUsers();
          });
        },
        // reject: ()
      });
    } else if (this.selectedUser.length === 0) {
      this.messageService.add({
        severity: 'error',
        // summary: 'Error',
        detail: 'Please select at least one field',
      });
    }
    // this.apiService.postBulkApproval()
  }

  // Bulk email sending function

  sendEmail(string: string, item: any) {
    var emailBoolean: any = true;
    if (string === 'bulk') {
      if (this.selectedUser.length !== 0) {
        // let approvalObj = [
        //   award_registrations: [{
        //     id: 1
        //   }]
        // ]
        let approvedId = [];
        this.selectedUser.forEach((element) => {
          if (element.status == 'Pending' || element.status == 'In Progress') {
            // debugger;
            emailBoolean = false;
            // return this.emailBoolean;
          } else {
            var id = element.id;
            approvedId.push({ id });
            // this.emailBoolean = true;
          }
        });
        console.log(emailBoolean);
        // debugger;
        if (emailBoolean == false) {
          this.spinner.hide();
          this.selectedUser = [];
          this.messageService.add({
            severity: 'warn',
            detail: 'Make sure status should be approved',
          });
        } else if (emailBoolean === true) {
          this.confirmationService.confirm({
            message: 'Are you sure you want to send mail to selected users?',
            accept: () => {
              this.spinner.show();
              console.log(approvedId);

              let obj: any = {
                // queue_approval: true,
                emails: approvedId,
              };
              console.log(obj);

              this.apiService.postBulkApproval(obj).subscribe((res: any) => {
                console.log(res);
                this.selectedUser = [];
                this.spinner.hide();
                this.messageService.add({
                  severity: 'success',
                  detail: res.message,
                });
                this.getUsers();
              });
            },
            // reject: ()
          });
        }
      } else if (this.selectedUser.length === 0) {
        this.messageService.add({
          severity: 'error',
          // summary: 'Error',
          detail: 'Please select at least one field',
        });
      }
    } else if (string === 'single') {
      if (item.status === 'Approved' || item.status === 'Rejected') {
        let approvedId = [];
        this.confirmationService.confirm({
          message: 'Are you sure you want to send mail to selected users?',
          accept: () => {
            this.spinner.show();
            let id = item.id;
            approvedId.push({ id });

            console.log(approvedId);

            let obj: any = {
              // queue_approval: true,
              emails: approvedId,
            };
            console.log(obj);

            this.apiService.postBulkApproval(obj).subscribe((res: any) => {
              console.log(res);
              this.selectedUser = [];
              this.spinner.hide();
              this.messageService.add({
                severity: 'success',
                detail: res.message,
              });
              // this.getUsers();
            });
          },
        });
      }
    }
  }
  async downloadXL(name: any) {
    let object = {
      category: name
    };
    this.spinner.show();
  
    this.apiService.getXL(object).then(
      (res: any) => {
        console.log(res);
        var blob = new Blob([res], { type: '.xls' });
        var url = window.URL.createObjectURL(blob);
        console.log(url);
        var anchor = document.createElement("a");
        anchor.download = name + ".xls";
        anchor.href = url;
        anchor.click();
  
        this.spinner.hide(); 
      },
      (error) => {
        console.error(error);
        this.spinner.hide(); 
      }
    );
  }

  //download form response as excel
  // async downloadXL(name: any) {
  //   let object = {
  //     category: name
  //   }
  //   this.spinner.show();
  //   await this.apiService.getXL(object).subscribe((res: any) => {
  //     console.log(res);
  //         var blob = new Blob([res], { type: '.xls' });
  //         var url = window.URL.createObjectURL(blob);
  //         console.log(url);
  //         var anchor = document.createElement("a");
  //         anchor.download = name + ".xls";
  //         anchor.href = url;
  //         anchor.click();
  //   })
  //   this.spinner.hide();
  // }

  //download pdf for every applicant
  async downloadPDF(user: any) {
    this.spinner.show();
    console.log(user);
    await this._formBuilder
      .getAPI('formWithData/'+user?.slug + '?id=' + user?.registration_id + '&user_id=' + user?.user_id )
      .then((res: any) => {
        console.log(res);
        this.award_category = res?.data[0]?.award_category;
        this.company_name = res?.data[0]?.award_category;
        if(res) {
          if(res?.data[0]?.form_response) {
            this.sections = JSON?.parse(
              JSON?.parse(res?.data[0]?.form_response)
            );
          }
          this.title = res?.data[0]?.form_title;
  
          if (res?.data[0]?.form_title === 'Service Award Form') {
            this.hindiTitle = 'सेवा क्षेत्र पुरस्कार';
          } else if (
            res?.data[0]?.form_title === 'Manufacturing Award Form'
          ) {
            this.hindiTitle = 'विनिर्माण क्षेत्र पुरस्कार';
          } else if (res?.data[0]?.form_title === 'Social Award Form') {
            // debugger;
            this.hindiTitle = 'सामाजिक उपक्रम पुरस्कार';
          } else if (res?.data[0]?.form_title === 'CSR Award Form') {
            this.hindiTitle = 'सामाजिक उत्तरदायित्व पुरस्कार';
          }
        }
      })

      let date = new Date();

      let year = date.getFullYear();
  
      this.nextYear = year;
      this.previewCheck = true;
      this.spinner.hide();
  }

  getPDF() {
    window.print();
  }
  notificationForm = this.fb.group({
    filter_name: ['', Validators.required],
    filter_string: [''],
    member_id: [''],
    message: ['', Validators.required],
    uploaded_file: [''],
  });
  sendMessage(payload) {

  }
get f() {
    return this.notificationForm.controls;
  }
  showFormDialog(string: string, item: any) {
      var emailBoolean: any = true;
      if (string === 'bulk') {
        if (this.selectedUser.length !== 0) {
          // let approvalObj = [
          //   award_registrations: [{
          //     id: 1
          //   }]
          // ]
          let approvedId = [];
          this.selectedUser.forEach((element) => {
            if (element.status == 'Pending' || element.status == 'In Progress') {
              // debugger;
              emailBoolean = false;
              // return this.emailBoolean;
            } else {
              var id = element.id;
              approvedId.push({ id });
              // this.emailBoolean = true;
            }
          });
          console.log(emailBoolean);
          // debugger;
          if (emailBoolean == false) {
            this.spinner.hide();
            this.selectedUser = [];
            this.messageService.add({
              severity: 'warn',
              detail: 'Make sure status should be approved',
            });
          }
           else if (emailBoolean === true) {
            this.displayForm = true;
            // this.confirmationService.confirm({
            //   message: 'Are you sure you want to send mail to selected users?',
            //   accept: () => {
            //     this.spinner.show();
            //     console.log(approvedId);
  
            //     let obj: any = {
            //       // queue_approval: true,
            //       emails: approvedId,
            //     };
            //     console.log(obj);
  
            //     this.apiService.postBulkApproval(obj).subscribe((res: any) => {
            //       console.log(res);
            //       this.selectedUser = [];
            //       this.spinner.hide();
            //       this.messageService.add({
            //         severity: 'success',
            //         detail: res.message,
            //       });
            //       this.getUsers();
            //     });
            //   },
            //   // reject: ()
            // });
          }
        } else if (this.selectedUser.length === 0) {
          this.messageService.add({
            severity: 'error',
            // summary: 'Error',
            detail: 'Please select at least one field',
          });
        }
      } 
      else if (string === 'single') {
        if (item.status === 'Approved' || item.status === 'Rejected') {
          let approvedId = [];
          this.displayForm = true;
          // this.confirmationService.confirm({
          //   message: 'Are you sure you want to send mail to selected users?',
          //   accept: () => {
          //     this.spinner.show();
          //     let id = item.id;
          //     approvedId.push({ id });
  
          //     console.log(approvedId);
  
          //     let obj: any = {
          //       // queue_approval: true,
          //       emails: approvedId,
          //     };
          //     console.log(obj);
  
          //     this.apiService.postBulkApproval(obj).subscribe((res: any) => {
          //       console.log(res);
          //       this.selectedUser = [];
          //       this.spinner.hide();
          //       this.messageService.add({
          //         severity: 'success',
          //         detail: res.message,
          //       });
          //       // this.getUsers();
          //     });
          //   },
          // });
        }
      }
    // if (this.selectedMembers?.length != 0) {
    //   this.displayForm = true;
    //   this.memberId = this.selectedMembers.map(element => element.id);
    // } else if (this.selectedMembers.length == 0) {
    //   this.messageService.add({
    //     severity: 'error',
    //     detail: 'Please Select at Least One Field!',
    //   });
    // }
  }
  onGstTypeSelection(value: string) {
    console.log('GST Type selected:', value);
    this.gstType = value;
  
    const gstControl = this.registerForm.get('company_GST');
    if (value === 'nonRegistered') {
      gstControl.clearValidators();
      gstControl.setValue('');
    } else if (value === 'registered') {
      gstControl.setValidators(Validators.required);
    }
    gstControl.updateValueAndValidity();
  }
  getClassification() {
    this.apiService.getClassification().subscribe((res: any) => {
      console.log("Classification",res);
      // this.classification = res.data;
      this.commonClasifications = res.data;
    });
  }

  onAwardTypeSelection(event: any) {
    const awardType = event.target ? event.target.value : event;

    console.log('Award Type selected:', awardType);
  
    // Convert backend value to frontend key if necessary
    let awardTypeKey = awardType;
    if (awardType == 'social enterprise award') {
      awardTypeKey = 'se';
    } else if (awardType == 'CSR Award') {
      awardTypeKey = 'csr';
    } else if (awardType == 'Excellence Award') {
      awardTypeKey = 'excellence';
    }

    this.registerForm.get('award_type').setValue(awardTypeKey);

  
    if (awardType === 'excellence') {
      this.registerForm.get('turnover').setValidators([
        Validators.required,
        Validators.min(100000),
      ]);
      this.classification = this.commonClasifications.filter((element: any) => {
        return element.category !== 'Social Enterprise';
      });
    } else if (awardType === 'csr') {
      this.registerForm.get('turnover').clearValidators();
      this.classification = this.commonClasifications;
    } else if (awardType === 'se'  || awardType == 'social enterprise award') {
      this.registerForm.get('turnover').clearValidators();
      this.classification = this.commonClasifications.filter((element: any) => {
        return element.category === 'Social Enterprise';
      });
    }
  
    this.registerForm.get('turnover').updateValueAndValidity();
    this.registerForm.get('classification_id').setValue(null);  // Reset classification when award type changes
    console.log('Filtered classifications:', this.classification);
  }
  getState() {
    this.apiService.getDataFromJson('assets/state.json').subscribe(
      (res: any) => {
        this.state = res.Data.map(state => ({
          name: state.name,
          value: state.name
        }));
        console.log('States loaded:', this.state);
  
        // After loading states, ensure the form control value is set
        const currentState = this.registerForm.get('company_state').value;
        if (currentState) {
          const matchingState = this.state.find(s => 
            s.name.toLowerCase() === currentState.toLowerCase()
          );
          if (matchingState) {
            this.registerForm.get('company_state').setValue(matchingState);
            console.log('State matched and set to:', matchingState);
          } else {
            console.log('No matching state found for:', currentState);
          }
        }
      },
      error => {
        console.error('Error loading states:', error);
      }
    );
  }
  populateEditForm(user: any) {
    this.registerForm.patchValue({
      name: user.name,
      mobile_no: user.mobile_no,
      email: user.email,
      company_name: user.company_name,
      company_GST: user.company_GST,
      company_PAN: user.company_PAN,
      company_state: user.company_state,
      company_city: user.company_city,
      company_pincode: user.company_pincode,
      company_address: user.company_address,
      address_line2: user.address_line2,
      turnover: user.turnover,
      category: user.category,
      achievements: user.achievements,
      reason: user.reason,
      remark: user?.remark,
      // Don't set classification_id here
    });
  
    console.log('Set company_state to:', user.company_state);
    this.getState();
  
    // Handle GST Type
    let gstType: string;
    if (user.gst_type === 'Non Registered GST' || user.gst_type === 'nonRegistered') {
      gstType = 'nonRegistered';
    } else if (user.gst_type === 'Registered GST' || user.gst_type === 'registered') {
      gstType = 'registered';
    } else {
      gstType = ''; // This will select the "Select GST Type" option
    }
    this.registerForm.get('gst_type').setValue(gstType);
    console.log('Set gst_type to:', gstType);
    this.onGstTypeSelection(gstType);
  
    // Handle Award Type first
  if (user.award_type) {
    const awardType = user.award_type.toLowerCase().trim();
    console.log('Setting award type to:', awardType);
    this.registerForm.get('award_type').setValue(awardType);
    this.onAwardTypeSelection({ target: { value: awardType } });
  }

  // Now handle Classification
  console.log('User classification_id:', user.classification_id);
  console.log('Filtered Classifications:', this.classification);

  if (user.classification_id && this.classification) {
    const matchingClassification = this.classification.find(c => c.id === user.classification_id);
    console.log('Matching classification:', matchingClassification);

    if (matchingClassification) {
      this.registerForm.get('classification_id').setValue(matchingClassification);
      console.log('Classification set to:', matchingClassification);
    } else {
      console.log('No matching classification found for ID:', user.classification_id);
    }
  } else {
    console.log('Either user.classification_id or this.classification is falsy');
  }
    console.log('Form populated:', this.registerForm.value);
  }

  onSubmitEdit() {
    if (this.registerForm.valid) {
      this.spinner.show();
      this.spinnerMsg = 'Updating';

      const userId = this.editingUser.id;
      const formData = new FormData();

      // Create a Set to keep track of keys we've already processed
      const processedKeys = new Set<string>();

      Object.keys(this.registerForm.value).forEach(key => {
        if (processedKeys.has(key)) return; // Skip if we've already processed this key

        let value = this.registerForm.get(key).value;
        
        if (key === 'classification_id' && typeof value === 'object') {
          formData.append(key, value.id);
          formData.append('category', value.category);
          processedKeys.add('category'); // Mark 'category' as processed
        } else if (key === 'company_state' && typeof value === 'object') {
          formData.append(key, value.name);
        } else if (key === 'company_address') {
          const address = this.registerForm.value.address_line2 && this.registerForm.value.address_line2 !== 'undefined'
            ? `${value}, ${this.registerForm.value.address_line2}`
            : value;
          formData.append(key, address);
          processedKeys.add('address_line2'); // Mark 'address_line2' as processed
        } else if (key !== 'address_line2') { // Skip 'address_line2' as it's handled with 'company_address'
          formData.append(key, value);
        }

        processedKeys.add(key);
      });

      // Append the remark only once
      if (!processedKeys.has('remark')) {
        formData.append('remark', this.registerForm.get('remark').value);
      }

      this.apiService.updateUser(userId, formData).subscribe(
        (res) => {
          this.spinner.hide();
          this.messageService.add({
            severity: 'success',
            detail: 'User updated successfully',
          });
          this.displayMaximizableEdit = false;
          this.getUsers(); // Refresh the user list
        },
        (error) => {
          this.spinner.hide();
          this.messageService.add({
            severity: 'error',
            detail: 'Error updating user: ' + (error.message || 'Unknown error'),
          });
        }
      );
    } else {
      this.errorMessage();
    }
  }

  errorMessage() {
    this.messageService.add({
      severity: 'error',
      detail: 'Please fill all details',
    });
  }
}
