<!-- Start Navbar Area -->
<p-toast [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display:
    'flex', 'justify-content': 'center', 'align-items': 'center',
    'flex-direction': 'column'}}">
</p-toast>
<app-spinner [msg]="spinnerMsg"></app-spinner>
<div class="navbar-area navbar-style-two">
    <div class="vesax-responsive-nav">
        <div class="container">
            <div class="vesax-responsive-menu" *ngIf="localStorage">
                <div class="logo">
                    <a class="navbar-brand" routerLink="/">
                        <img src="assets/img/UCCI_LOGO.png" alt="logo" height="180px" width="180px">

                    </a>
                </div>
                <!-- <a *ngIf="!localStorage" routerLink="/admin-login" class="admin-link">Admin
                    Login</a> -->
            </div>
            <div class="vesax-responsive-menu d-flex" *ngIf="!localStorage">
                <div class="logo" style="width: 82%;">
                    <a class="navbar-brand" (click)="onLogoClick()"><img src="assets/img/UCCI_LOGO.png" alt="logo"
                            height="180px" width="180px"></a>
                </div>
                <!-- <a *ngIf="!localStorage" routerLink="/admin-login" class="admin-link">Admin
                    Login</a> -->
            </div>
        </div>
    </div>
    <div class="vesax-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light" style="justify-content: space-between;">

                <a class="navbar-brand" style="padding: 14px 0;" (click)="onLogoClick()"><img
                        src="assets/img/UCCI_LOGO.png" alt="logo"></a>
                <!-- <div> -->
                <!-- <a *ngIf="!localStorage" routerLink="/admin-login" class="admin-link">Admin Login</a> -->
                <!-- </div> -->
                <div class="collapse navbar-collapse mean-menu" *ngIf="localStorage">
                    <!-- <form class="navbar-search-box">
                        <label><i class="flaticon-search"></i></label>
                        <input type="text" class="input-search"
                            placeholder="What are you looking for?">
                    </form> -->
                    <ul class="navbar-nav">
                        <li class="nav-item"><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                true}" class="nav-link">Home</a>
                        </li>
                        <!-- {{menuItems | json}}
                        <li *ngFor="let item of menuItems">
                            {{item | json}}
                        </li> -->
                        <!-- <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Services</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/certificate-of-origin-form" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                    true}">Certificate of Origin</a></li>

                                <li class="nav-item"><a routerLink="/document-attestation-form" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                    true}">Document Attestation</a></li>
                                <li class="nav-item"  *ngIf="role != 'Guest' && localStorage"><a routerLink="/raise-issues" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                    true}">Raise Issues</a></li>
                                    <li class="nav-item" *ngIf="role != 'Guest' && localStorage"><a routerLink="/visa-recommendation" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Visa Recommendation</a></li>
                                    <li class="nav-item" *ngIf="role != 'Guest' && localStorage"><a routerLink="/facility-booking" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Facility Booking</a></li>
                                        
                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Apply for Membership <i
                                            class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/regular-member-form" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                true}">Regular Member</a></li>

                                        <li class="nav-item"><a routerLink="/hwm-registration" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">HWM Member</a></li>
                                        <li class="nav-item"><a routerLink="/non-member-registration" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Registered Non Member</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </li> -->
                        <!-- {{role}} -->
                        <ng-container *ngIf="role === 'Admin'">
                            <!-- <a href="javascript:void(0)"
                                class="dropdown-toggle nav-link">User Panel</a>
                            <ul class="dropdown-menu"> -->
                            <li class="nav-item"><a routerLink="/award-configuration" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                    true}">Award Configuration</a></li>

                            <li class="nav-item"><a routerLink="/registered-user" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                    true}">Registered User</a></li>
                            <li class="nav-item"><a routerLink="/custom-forms" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                    true}">Form Builder</a></li>
                            <li class="nav-item" >
                                <a routerLink="/site-visit-settings" class="nav-link"  [routerLinkActiveOptions]="{exact:
                                    true}">Site Visit Settings
                                </a>
                            </li>
                            <!-- <ng-container>

                                <li *ngFor="let item of awardForm">
                                    {{item | json}}
                                </li>
                            </ng-container> -->
                            <!-- <ng-container *ngIf="menuItems?.length> 0"> -->
                            <!-- <ng-container > -->
                            <!-- <li *ngIf="index <4" class="nav-item" routerLinkActive="active mm-active">
                                    <a (click)="navigate('/form-lib/'+item.slug)" class="nav-link">
                                        <span class="icon"><i class='bx
                                                    bx-home-circle'></i></span>
                                        <span class="menu-title">{{item.form_title}}</span>
                                    </a>
                                </li> -->

                            <!-- <li class="nav-item" *ngFor="let item of
                            menuItems;index as index;"><a routerLink="/custom-forms" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                    true}">Form Builder</a></li> -->
                            <!-- </ng-container> -->
                            <!-- </ng-container> -->
                            <!-- {{awardForm | json}}
                            <ng-container *ngFor="let item of awardForm;index as index;">

                                <li *ngIf="index < 4" class="nav-item"><a routerLink="/custom-forms" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                                true}">{{index}}</a></li>
                            </ng-container> -->
                            <!-- <li class="nav-item"><a routerLink="/dashboard-messages" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Messages</a></li>

                                <li class="nav-item"><a routerLink="/dashboard-bookings" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Bookings</a></li>

                                <li class="nav-item"><a routerLink="/dashboard-wallet" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Wallet</a></li>

                                <li class="nav-item"><a routerLink="/dashboard-my-listings" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">My Listings</a></li>

                                <li class="nav-item"><a routerLink="/dashboard-reviews" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Reviews</a></li>

                                <li class="nav-item"><a routerLink="/dashboard-bookmarks" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Bookmarks</a></li>

                                <li class="nav-item"><a routerLink="/dashboard-add-listings" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Add Listings</a></li>

                                <li class="nav-item"><a routerLink="/dashboard-my-profile" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">My Profile</a></li>

                                <li class="nav-item"><a routerLink="/dashboard-invoice" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Invoice</a></li> -->
                            <!-- </ul>
                        </li> -->
                        </ng-container>
                        <ng-container *ngIf="localStorage && role === 'User'">
                            <!-- <a href="javascript:void(0)"
                                class="dropdown-toggle nav-link">User Panel</a>
                            <ul class="dropdown-menu"> -->
                            <!-- <li class="nav-item"><a routerLink="/award-configuration" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                    true}">Award Configuration</a></li> -->
                            <li class="nav-item" routerLinkActive="active mm-active">
                                <a href="javascript:void(0)" class="collapsed-nav-link nav-link" aria-expanded="false">
                                    <span class="icon"><i class='bx bx-layer'></i></span>
                                    <span class="menu-title">My Registrations</span>
                                </a>
                                <ul class="sidemenu-nav-second-level">
                                    <li class="nav-item" routerLinkActive="active">
                                        <a routerLink="/registered-user" class="nav-link">
                                            <span class="menu-title">Stage One</span>
                                            <!-- <span class="badge">5</span> -->
                                        </a>
                                    </li>
                                    <li class="nav-item" routerLinkActive="active">
                                        <a routerLink="/stage-two" class="nav-link">
                                            <span class="menu-title">Stage Two</span>
                                            <!-- <span class="badge yellow">1</span> -->
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            <!-- </ul> -->
                        </ng-container>


                        <!-- <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Shop</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/products-list" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Products List</a></li>

                                <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Cart</a></li>

                                <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Checkout</a></li>

                                <li class="nav-item"><a routerLink="/single-products" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Products Details</a></li>
                            </ul>
                        </li> -->
                        <!-- <li class="nav-item"><a routerLink="/blog-grid" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                            true}">Events</a>
                        </li> -->
                        <!-- <li class="nav-item"><a routerLink="/about" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                            true}" class="nav-link">About Us</a></li> -->
                    </ul>

                    <div class="others-option d-flex align-items-center">
                        <!-- <div class="option-item" *ngIf="!localStorage">
                            <span data-bs-toggle="modal" data-bs-target="#loginRegisterModal"><i
                                    class="flaticon-user"></i> Login / Register</span>
                        </div> -->
                        <div class="option-item" *ngIf="localStorage">
                            <div class="dropdown profile-nav-item">
                                <a href="#" class="dropdown-toggle" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <div class="menu-profile">
                                        <span class="name">My Account</span>
                                    </div>
                                </a>
                                <div class="dropdown-menu">
                                    <div class="dropdown-header d-flex
                                        flex-column align-items-center">
                                        <div class="info text-center">
                                            <span class="name" style="color:
                                                #000;">{{userName | titlecase}}</span>
                                            <p class="mb-3 email">{{userEmail}}</p>
                                        </div>
                                    </div>
                                    <!-- <div class="dropdown-body">
                                        <ul class="profile-nav p-0 pt-3"> -->
                                    <!-- <li class="nav-item"><a routerLink="/dashboard" class="nav-link"><i
                                                        class='bx bx-user'></i> <span>Dashboard</span></a></li> -->
                                    <!-- <li class="nav-item"><a routerLink="/dashboard-messages" class="nav-link"><i
                                                        class='bx bx-envelope'></i> <span>Messages</span></a></li>
                                            <li class="nav-item"><a routerLink="/dashboard-bookings" class="nav-link"><i
                                                        class='bx bx-edit-alt'></i> <span>Bookings</span></a></li>
                                            <li class="nav-item"><a routerLink="/dashboard-my-profile"
                                                    class="nav-link"><i class='bx bx-cog'></i> <span>Settings</span></a> -->
                                    <!-- </li> -->
                                    <!-- </ul>
                                    </div> -->
                                    <div class="dropdown-footer">
                                        <ul class="profile-nav">
                                            <li class="nav-item">
                                                <div>
                                                    <a class="nav-link" (click)="logout()"><i class='bx
                                                            bx-log-out'></i>
                                                        <span>Logout</span></a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="option-item">
                            <a routerLink="/dashboard-add-listings" class="default-btn"><i class="flaticon-more"></i>
                                Add Listing</a>
                        </div> -->
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <!-- <div class="others-option-for-responsive" *ngIf="localStorage">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>

            <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                        <div class="option-item">
                            <div class="info text-center" style="border-bottom: 1px solid #f1e9e9;">
                                <span class="name" style="color: #000;">{{userName | titlecase}}</span>
                                <p class="mb-2 email">{{userEmail}}</p>
                            </div>
                            <span class="nav-item mt-2"><a routerLink="/dashboard" class="nav-link"><i
                                        class='bx bx-user'></i> <span>Dashboard</span></a></span>
                        </div>

                        <div class="option-item">
                            <span>
                                <a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i>
                                    <span>Logout</span></a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <div class="others-option-for-responsive" *ngIf="localStorage">

        <div class="others-option d-flex align-items-center
            justify-content-end">
            <div class="option-item">
                <div class="dropdown profile-nav-item">
                    <a href="#" class="dropdown-toggle" role="button" data-bs-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                        <div class="menu-profile">
                            <!-- <img src="assets/img/user1.jpg" class="rounded-circle" alt="image"> -->
                            <span class="name">My Account</span>
                        </div>
                    </a>
                    <div class="dropdown-menu">
                        <div class="dropdown-header d-flex flex-column
                            align-items-center">
                            <div class="figure mb-3">
                                <!-- <img src="assets/img/user1.jpg" class="rounded-circle" alt="image"> -->
                            </div>

                            <div class="info text-center">
                                <span class="name">{{userName | titlecase}}</span>
                                <p class="mb-3 email">{{userEmail}}</p>
                            </div>
                        </div>
                        <!-- <div class="dropdown-body">
                            <ul class="profile-nav p-0 pt-3">
                                <li class="nav-item"><a routerLink="/dashboard" class="nav-link"><i
                                            class='bx bx-user'></i>
                                        <span>Dashboard</span></a></li>
                            </ul>
                        </div> -->
                        <div class="dropdown-footer">
                            <ul class="profile-nav">
                                <li class="nav-item">

                                    <a class="nav-link" (click)="logout()"><i class='bx bx-log-out'></i>
                                        <span>Logout</span></a>
                                    <!-- <a routerLink="/" class="nav-link"><i class='bx bx-log-out'></i> <span>Logout</span></a> -->
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Navbar Area -->

<!-- Start Login & Register Modal -->

<!-- End Login & Register Modal -->