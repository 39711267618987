<p-toast
  [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center'}}">
</p-toast>
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<!-- <ngx-spinner bdColor = "rgba(0, 0, 0, 0.8)" size = "medium" color = "red" type="square-jelly-box" [fullScreen] = "true"><p style="color: white" > Loading... </p></ngx-spinner> -->
<app-spinner></app-spinner>

<!-- <ngx-spinner name="sp5" [fullScreen]="true" type="square-jelly-box" size="medium"></ngx-spinner> -->
<app-dashboard-sidemenu></app-dashboard-sidemenu>

<!-- ********************** Main Content ******************** -->

<div class="main-content d-flex flex-column">

  <!-- <app-dashboard-navbar></app-dashboard-navbar> -->
  <app-navbar-style-one></app-navbar-style-one>

  <!-- ********************** Breadcrumb Area  ******************** -->

  <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
    <h1>{{Content.title}}</h1>
    <ol class="breadcrumb">
      <li class="item"><a routerLink="/">Home</a></li>
      <!-- <li class="item"><a routerLink="/dashboard">{{Content.subTitle}}</a></li> -->
      <li class="item">{{Content.title}}</li>
    </ol>
  </div>

  <!-- ********************** End Breadcrumb Area  ******************** -->

  <!-- ********************** Add Master Form Area  ******************** -->

  <div class="add-listings-box">
    <form [formGroup]="serviceMasterForm" (ngSubmit)="onSubmit(form)" #form="ngForm">
      <div class="row mt-1">
        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
          <div class="form-group">Name<span style="color: red;">*</span>:
            <input type="text" class="form-control" placeholder="Name" formControlName="name" required
              [class.is-invalid]="(form.submitted && serviceMasterForm.get('name')?.invalid) || serviceMasterForm.get('name')?.invalid && serviceMasterForm.get('name').touched">
            <div class="invalid-feedback">Name is required</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
          <div class="form-group">URL<span style="color: red;">*</span>:
            <input type="text" class="form-control" placeholder="URL" formControlName="slug" required
              [class.is-invalid]="(form.submitted && serviceMasterForm.get('slug')?.invalid) || serviceMasterForm.get('slug')?.invalid && serviceMasterForm.get('slug').touched">
            <div class="invalid-feedback">URL is required</div>
          </div>
        </div>
        <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12">
          <div class="form-group">Description<span style="color: red;">*</span>:
            <input type="text" class="form-control" placeholder="Description" formControlName="sort_description"
              required
              [class.is-invalid]="(form.submitted && serviceMasterForm.get('sort_description')?.invalid) || serviceMasterForm.get('sort_description')?.invalid && serviceMasterForm.get('sort_description').touched">
            <div class="invalid-feedback">Description is required</div>
          </div>
        </div>
        <!-- <div class="col-lg-1 col-md-4 col-sm-4 col-xs-12"></div> -->
        <div class="col-lg-3 col-md-2 col-sm-2 col-xs-12" style="text-align: right;">
          <div class="add-listings-btn">
            <button type="submit">Add</button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <!-- ********************** End Add Master Form Area  ******************** -->

  <!-- ********************** Master View Area  ******************** -->

  <div class="Master-view-box">
    <div class="theader">
      <!-- <ng-template pTemplate="caption"> -->
      <div class="flex">
        <span class="p-input-icon-left ml-auto">
          <i class="pi pi-search"></i>
          <input pInputText type="text" (input)="dt1.filterGlobal($event.target.value, 'contains')"
            placeholder="Search keyword" [(ngModel)]="filterVal" />
        </span>
        <p-button type="button" class="ml-2" (click)="reset(dt1)" icon="bx bx-reset"></p-button>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12 col-md-12">
        <p-table #dt1 [value]="serviceData" dataKey="id" [rows]="10" [showCurrentPageReport]="true"
          styleClass="p-datatable-sm p-datatable-customers" [rowsPerPageOptions]="[10,25,50]" editMode="row"
          responsiveLayout="scroll" [paginator]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          [globalFilterFields]="['component']">
          <ng-template pTemplate="header" style="min-height: 500px;">
            <tr>
              <th style="max-width: 25px; text-align: center;">S. No.</th>
              <th style="min-width: 130px;">Name</th>
              <th style="min-width: 130px;">URL</th>
              <th style="min-width: 130px;">Description</th>
              <th style="min-width: 70px;"></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-item let-i="rowIndex" let-editing="editing">
            <tr [pEditableRow]="item">
              <td style="text-align: center;">
                {{i+1}}
              </td>
              <td>
                <p-cellEditor editingRowKeys="item.id">
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="item.name" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{item.name}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor editingRowKeys="item.id">
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="item.slug" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{item.slug}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td>
                <p-cellEditor editingRowKeys="item.id">
                  <ng-template pTemplate="input">
                    <input pInputText type="text" [(ngModel)]="item.sort_description" required>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{item.sort_description}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td style="text-align: right;">
                <button *ngIf="!editing" pButton pRipple icon="bx bx-pencil"
                  class="p-button-rounded p-button-success p-button-text mr-2" pInitEditableRow
                  (click)="editService(item)"></button>

                <button *ngIf="!editing" pButton pRipple icon="bx bx-trash"
                  class="p-button-rounded p-button-danger p-button-text" (click)="deleteService(item)"></button>

                <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check"
                  (click)="saveEditService(item)" class="p-button-rounded p-button-success p-button-text mr-2"></button>
                <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"
                  (click)="onRowEditCancel(item, i)" class="p-button-rounded p-button-danger p-button-text"></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="5">No Data Found.</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>

  <!-- ********************** End Master View Area  ******************** -->

  <div class="flex-grow-1"></div>

  <!-- ********************** Copyrights Area  ******************** -->

  <app-copyrights></app-copyrights>

  <!-- ********************** End Copyrights Area  ******************** -->

</div>