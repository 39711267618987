import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { ApiService } from 'src/app/services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessageService } from 'primeng/api';
@Component({
  selector: 'app-site-visit-settings',
  templateUrl: './site-visit-settings.component.html',
  styleUrls: ['./site-visit-settings.component.scss'],
  
})
export class SiteVisitSettingsComponent implements OnInit {
  @ViewChild('dt1') dt1: Table;
  breadcrumb: any;
  filterVal: string;
  data: any = [];
  awardsData: any;
  awardsQuestions: any;
  allControlsData: any = [];
  checkedArrayCsr: any = [];
  csrId:any;
  toolListChecked:any = [];
  isTabDisabled:boolean = false;
  constructor(
    private apiService: ApiService,
    private spinner: NgxSpinnerService,
    private messageService: MessageService,
  ) {
   
  }

  ngOnInit(): void {
    this.breadcrumb = [
      {
        title: 'Site Visit Settings',
        subTitle: 'Home',
      },
    ];
     this.getData();
   
  }
  onSearch(type: string, e: any) {
    // if(type == "dt1") {
    this.dt1.filterGlobal(e, 'contains');
    // }else if(type == "dt1") {
    //   this.dt1.filterGlobal(e, 'contains')
    // }
  }
  reset() {
    this.dt1.reset();
    this.filterVal = '';
  }
  //all forms questions
  async getData() {
    try {
      const res = await this.apiService.getDropdownName().toPromise();
      console.log(res);
      let users: any = res?.data;
      this.data = users;
      console.log(this.data);
      this.spinner.hide();
      this.csrId = this.data[3]?.id;
      // Continue with the rest of the code after getting the data
      const formJson = JSON.parse(JSON.parse(this.data[3].form_json));
  
      this.awardsData = formJson;
  
      for (const index in this.awardsData) {
        if (this.awardsData.hasOwnProperty(index)) {
          const controlsArray = this.awardsData[index].controls;
  
          if (controlsArray && Array.isArray(controlsArray)) {
            // Filter out controls with null label before pushing to allControlsData
            const validControlsArray = controlsArray.filter(control => control.label !== '');
  
            this.allControlsData.push(...validControlsArray);
          }
        }
      }
  
      // Call getSiteVisitData after completing the getData function
      await this.getSiteVisitData();
    } catch (error) {
      console.error('Error in getData:', error);
    }
  }
  
  async getSiteVisitData() {
    try {
      const res = await this.apiService.getSiteVisitData(this.csrId).toPromise();
      console.log('getSiteVisitData:', res);
      if((res as any)?.data !== null){
        const data = JSON.parse((res as any)?.data);
        this.checkedArrayCsr = data
        data.forEach((element, index) => {
          this.toolListChecked[element.isIndex] = true;
          console.log(`Element at index ${index}: ${element.isIndex}`);
        }); 
      }else{

      } 
      // Continue processing the result of getSiteVisitData
    } catch (error) {
      console.error('Error in getSiteVisitData:', error);
    }
  }
  
  onTabChange(event: any) {
    this.checkedArrayCsr = [];
    this.toolListChecked = []
    this.awardsData = [];
    this.allControlsData = [];
    console.log(event.index);
    console.log('clicked');
  
    const indexMap = [3, 0, 1, 2];
    const formJson = JSON.parse(JSON.parse(this.data[indexMap[event.index]].form_json));
    console.log(this.data[indexMap[event.index]].id)
    this.csrId = this.data[indexMap[event.index]].id
    this.awardsData = formJson;
    this.getSiteVisitData()
  
    // for (const index in this.awardsData) {
    //   if (this.awardsData.hasOwnProperty(index)) {
    //     const controlsArray = this.awardsData[index].controls;
    //     if (controlsArray && Array.isArray(controlsArray)) {
    //       this.allControlsData.push(...controlsArray);
    //     }
    //   }
    // }
    for (const index in this.awardsData) {
      if (this.awardsData.hasOwnProperty(index)) {
          const controlsArray = this.awardsData[index].controls;
  
          if (controlsArray && Array.isArray(controlsArray)) {
              // Filter out controls with null label, and label's first character not being an alphabet
              const validControlsArray = controlsArray.filter(control => control.label !== null && /^[^A-Za-z]/.test(control.label[0]));
  
              this.allControlsData.push(...validControlsArray);
          }
      }
  }
  
  
  
  
    console.log(this.allControlsData);
  } 
  tabClick(event:any){
    console.log(event.target.outerText);
    if(this.isTabDisabled == true){
      if(event.target.outerText.includes('CSR Award')){
        this.messageService.add({
          severity: 'error',
          detail: 'Kindly submit the selected questions to switch tab!',
        });
      }
    }
  else{

  }
  }
  handleCheckBoxClick(event: any, string: any, data: any, index: any) {
    this.toolListChecked[3] = true
        console.log("i", index);
        
        const dataIndex = this.checkedArrayCsr.findIndex(item => item.label === data.label );
    
        if (event.target.checked) {
          // Add to the array if it doesn't already exist
          data.isIndex = index
          console.log(data)
          if (dataIndex === -1) {
            this.checkedArrayCsr.push(data);
          }
        } else {
          // Remove from the array if it exists
          if (dataIndex !== -1) {
            this.checkedArrayCsr.splice(dataIndex, 1);
          }
        }
    
        console.log(this.checkedArrayCsr);
        // if(this.checkedArrayCsr.length !== 0){
        //   this.isTabDisabled = true;
        // }
        // else if(this.checkedArrayCsr.length == 0){
        //   this.isTabDisabled = false;   
        // }
    }
    saveData(string:any){
      this.spinner.show();
      // if(string == 'csr'){
        if(this.checkedArrayCsr.length > 0){
        const data = {
          site_visit_json: this.checkedArrayCsr
        }
        this.apiService.sendSiteVisitData(this.csrId,data).then((res:any) => {
          this.spinner.hide();
          this.toolListChecked = [];
          this.checkedArrayCsr = []
          this.messageService.add({
            severity: 'success',
            detail: 'Questions added successfully!',
          });
          console.log(res);
          this.isTabDisabled = false;   
        })
      }
      else {
        this.spinner.hide();
        this.messageService.add({
          severity: 'error',
          detail: 'Please Select Questions First!',
        });
      }
    
    // // }
    //  else if(string == 'social'){

    //   }
    //   else if(string == 'service'){
        
    //   }
    //   else if(string == 'manufacturing'){
        
    //   }
    }
   
      }
    
    

