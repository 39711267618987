<app-spinner></app-spinner>
<!-- <p-dialog header="Title" [(visible)]="displayPopUp" [modal]="true">
    Content
</p-dialog> -->
<!-- <p-dialog header="Header" [(visible)]="displayPopUp" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
    <p class="m-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
        laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
        Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
        <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="displayModal=false" label="Ok" styleClass="p-button-text"></p-button>
        </ng-template>
</p-dialog> -->
<p-confirmDialog [style]="{width: '50vw'}" [baseZIndex]="10000" rejectButtonStyleClass="p-button-text">
</p-confirmDialog>
<!-- <app-dashboard-sidemenu></app-dashboard-sidemenu> -->

<!-- Start Main Content Wrapper Area -->
<div class="main-content d-flex flex-column" style="padding-left: 30px;">

    <!-- <app-dashboard-navbar></app-dashboard-navbar> -->
    <app-navbar-style-one></app-navbar-style-one>

    <!-- Breadcrumb Area -->
    <div class="breadcrumb-area" *ngFor="let Content of breadcrumb;">
        <h1>{{userName | titlecase}}</h1>
        <ol class="breadcrumb">
            <!-- <li class="item"><a routerLink="/dashboard">Home</a></li> -->
            <li class="item">{{Content.subTitle}}</li>
        </ol>
    </div>
    <!-- End Breadcrumb Area -->

    <!-- Start -->
    <!-- <div class="notification-alert alert alert-success alert-dismissible fade show" role="alert">
        Your listing <strong>Hills Hotel</strong> has been approved!
        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
    </div> -->
    <!-- End -->

    <!-- Start -->
    <!-- <app-stats></app-stats> -->
    <!-- End -->

    <!-- Start -->
    <div class="row">

        <!-- <div class="col-lg-6 col-md-12">
            <div class="invoices-box">
                <h3>Invoices</h3>

                <ul>
                    <li>
                        <div class="icon">
                            <i class='bx bx-file-blank'></i>
                        </div>
                        <ul>
                            <li class="paid">Paid</li>
                            <li>Order: #181815</li>
                            <li>Date: 14/08/2020</li>
                        </ul>
                        <span>Premium Plan</span>
                        <a href="dashboard-invoice.html" class="default-btn">View Invoice</a>
                    </li>
                    <li>
                        <div class="icon">
                            <i class='bx bx-file-blank'></i>
                        </div>
                        <ul>
                            <li class="unpaid">Unpaid</li>
                            <li>Order: #181814</li>
                            <li>Date: 13/08/2020</li>
                        </ul>
                        <span>Advance Plan</span>
                        <a href="dashboard-invoice.html" class="default-btn">View Invoice</a>
                    </li>
                    <li>
                        <div class="icon">
                            <i class='bx bx-file-blank'></i>
                        </div>
                        <ul>
                            <li class="paid">Paid</li>
                            <li>Order: #181813</li>
                            <li>Date: 12/08/2020</li>
                        </ul>
                        <span>Starter Plan</span>
                        <a href="#" class="default-btn">View Invoice</a>
                    </li>
                    <li>
                        <div class="icon">
                            <i class='bx bx-file-blank'></i>
                        </div>
                        <ul>
                            <li class="unpaid">Unpaid</li>
                            <li>Order: #181812</li>
                            <li>Date: 11/08/2020</li>
                        </ul>
                        <span>Basic Plan</span>
                        <a href="dashboard-invoice.html" class="default-btn">View Invoice</a>
                    </li>
                    <li>
                        <div class="icon">
                            <i class='bx bx-file-blank'></i>
                        </div>
                        <ul>
                            <li class="paid">Paid</li>
                            <li>Order: #181815</li>
                            <li>Date: 14/08/2020</li>
                        </ul>
                        <span>Premium Plan</span>
                        <a href="dashboard-invoice.html" class="default-btn">View Invoice</a>
                    </li>
                </ul>
            </div>
        </div> -->
    </div>
    <!-- End -->

    <!-- Start -->
    <div class="row">
        <ng-container *ngFor="let item of awardForm">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="stats-card-box">
                    <!-- <div> -->
                    <h3>{{item.form_title}}</h3>
                    <!-- </div> -->
                    <div>
                        <span class="sub-title stage-status"><span style="font-size: 1.2rem;">Status: </span>
                            <!-- style="background-color: #ffff; color: red; padding: 1px 8px; border-radius: 4px;" -->
                            <span *ngIf="item.status == ''" class="pending">Pending</span>
                            <span *ngIf="item.status == 'In Progress'" class="in-progress">{{item.status}}</span>
                            <span *ngIf="item.status == 'completed'" class="approved">{{item.status}}</span>

                        </span>
                    </div>
                    <div *ngIf="item.status !== 'completed'"><a routerLink="/form-lib/{{item.slug}}"
                            class="edit-btn">Edit</a></div>
                    <!-- <div class="icon-box">
                        <i class='bx bxs-badge-dollar'></i>
                    </div>
                    <span class="sub-title">Withdrawable Balance <strong class="wallet-currency">INR</strong></span>
                    <h3>00.00</h3> -->
                </div>
            </div>
        </ng-container>
        <!-- <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="stats-card-box">
                <div class="icon-box">
                    <i class='bx bxs-badge-dollar'></i>
                </div>
                <span class="sub-title">Withdrawable Balance <strong class="wallet-currency">INR</strong></span>
                <h3>00.00</h3>
            </div>
        </div> -->

        <!-- <div class="col-lg-4 col-md-6 col-sm-6">
            <div class="stats-card-box">
                <div class="icon-box">
                    <i class='bx bx-dollar'></i>
                </div>
                <span class="sub-title">Total Earnings <strong class="wallet-currency">INR</strong></span>
                <h3>00.00</h3>
            </div>
        </div>

        <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
            <div class="stats-card-box">
                <div class="icon-box">
                    <i class='bx bx-cart'></i>
                </div>
                <span class="sub-title">Total Orders</span>
                <h3>00</h3>
            </div>
        </div> -->
    </div>
    <!-- End -->
    <!-- Resent Activities start -->
    <div class="col-lg-12">
        <!-- <app-recent-activities (loading)="event($event)"></app-recent-activities> -->
    </div>
    <!-- Resent Activities End  -->


    <!-- Start -->
    <!-- <div class="row">
        <div class="col-lg-6 col-md-12">
            <div class="earnings-box">
                <h3>Earnings <span class="comission-taken">Fee: 10%</span></h3>

                <ul>
                    <li>
                        <div class="icon">
                            <i class='bx bx-cart'></i>
                        </div>
                        <ul>
                            <li>Date: 17/08/2020</li>
                            <li>Order: #181812</li>
                            <li class="price">$200.00</li>
                            <li class="fee-price">Fee: $20.00</li>
                            <li class="price">Net Earning: <strong>$180.00</strong></li>
                        </ul>
                        <span>Farmis Restaurant</span>
                    </li>
                    <li>
                        <div class="icon">
                            <i class='bx bx-shopping-bag'></i>
                        </div>
                        <ul>
                            <li>Date: 16/08/2020</li>
                            <li>Order: #181811</li>
                            <li class="price">$76.00</li>
                            <li class="fee-price">Fee: $6.00</li>
                            <li class="price">Net Earning: <strong>$70.00</strong></li>
                        </ul>
                        <span>Shopping Complex Center</span>
                    </li>
                    <li>
                        <div class="icon">
                            <i class='bx bx-cart'></i>
                        </div>
                        <ul>
                            <li>Date: 15/08/2020</li>
                            <li>Order: #181810</li>
                            <li class="price">$80.00</li>
                            <li class="fee-price">Fee: $5.00</li>
                            <li class="price">Net Earning: <strong>$75.00</strong></li>
                        </ul>
                        <span>Gym Training Center</span>
                    </li>
                    <li>
                        <div class="icon">
                            <i class='bx bx-shopping-bag'></i>
                        </div>
                        <ul>
                            <li>Date: 14/08/2020</li>
                            <li>Order: #181809</li>
                            <li class="price">$150.00</li>
                            <li class="fee-price">Fee: $25.00</li>
                            <li class="price">Net Earning: <strong>$125.00</strong></li>
                        </ul>
                        <span>The Magician Restaurant</span>
                    </li>
                    <li>
                        <div class="icon">
                            <i class='bx bx-cart'></i>
                        </div>
                        <ul>
                            <li>Date: 13/08/2020</li>
                            <li>Order: #181808</li>
                            <li class="price">$100.00</li>
                            <li class="fee-price">Fee: $10.00</li>
                            <li class="price">Net Earning: <strong>$90.00</strong></li>
                        </ul>
                        <span>Oriental Shopping Center</span>
                    </li>
                </ul>
            </div>
        </div>

        <div class="col-lg-6 col-md-12">
            <div class="earnings-box">
                <h3>Payout History</h3>

                <ul>
                    <li>
                        <div class="icon">
                            <i class='bx bx-wallet'></i>
                        </div>
                        <ul>
                            <li class="unpaid">Unpaid</li>
                            <li>Period: 08/2020</li>
                        </ul>
                        <span>$100.00</span>
                    </li>
                    <li>
                        <div class="icon">
                            <i class='bx bx-wallet'></i>
                        </div>
                        <ul>
                            <li class="paid">Paid</li>
                            <li>Period: 07/2020</li>
                        </ul>
                        <span>$70.00</span>
                    </li>
                    <li>
                        <div class="icon">
                            <i class='bx bx-wallet'></i>
                        </div>
                        <ul>
                            <li class="unpaid">Unpaid</li>
                            <li>Period: 06/2020</li>
                        </ul>
                        <span>$75.00</span>
                    </li>
                    <li>
                        <div class="icon">
                            <i class='bx bx-wallet'></i>
                        </div>
                        <ul>
                            <li class="unpaid">Unpaid</li>
                            <li>Period: 05/2020</li>
                        </ul>
                        <span>$125.00</span>
                    </li>
                    <li>
                        <div class="icon">
                            <i class='bx bx-wallet'></i>
                        </div>
                        <ul>
                            <li class="paid">Paid</li>
                            <li>Period: 04/2020</li>
                        </ul>
                        <span>$90.00</span>
                    </li>
                </ul>
            </div>
        </div>
    </div> -->
    <!-- End -->

    <div class="flex-grow-1"></div>

    <app-copyrights></app-copyrights>

</div>
<!-- End Main Content Wrapper Area -->